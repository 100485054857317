import React from "react";

const RewardCard = ({
  toEarn,
  reward,
  rewardDisclaimer,
  visitNumber,
  visitedTimes,
}) => {
  const isOfferValid = visitedTimes >= visitNumber;
  const isOfferExpired = visitedTimes > visitNumber;

  return (
    <div className="bg-white border-b-1  p-4 rounded-sm relative">
      <span
        className={`text-lg font-bold text-center absolute z-10 inset-0 flex justify-center items-center
        ${isOfferValid ? "hidden" : "block"}
            
    `}
      >
        {toEarn}
      </span>
      <span
        className={`text-md text-red-600 font-bold text-center absolute z-10 inset-0 flex justify-center items-center
        ${isOfferExpired ? "block" : "hidden"}

      `}
      >
        Offer Expired
      </span>
      <div
        className={`flex flex-col gap-2 
      ${isOfferExpired ? "blur-sm" : isOfferValid ? "blur-none" : "blur-sm"}
      `}
      >
        <span className="font-semibold">{reward}</span>
        <p className="text-sm font-medium">{rewardDisclaimer}</p>
      </div>
    </div>
  );
};

export default RewardCard;

/**
 * The RewardCard component is a reusable component used to display reward cards with different information, such as the reward to earn, the reward details, and the status of the offer (valid or expired).

**Component Props:

toEarn: A string representing the reward to earn.
reward: A string representing the details of the reward.
rewardDisclaimer: A string representing any disclaimer or additional information about the reward.
visitNumber: A number representing the required number of visits to earn the reward.
visitedTimes: A number representing the actual number of visits made.

**Component Behavior:

1. The component calculates whether the offer is valid or expired based on the visitedTimes and visitNumber props.
2. If visitedTimes is greater than or equal to visitNumber, the offer is considered valid. If visitedTimes is greater than visitNumber, the offer is considered expired.
3. The component conditionally renders the "toEarn" and "Offer Expired" messages based on the offer status.
4. The reward details and disclaimer are displayed in a blurred manner if the offer is expired.

 */
