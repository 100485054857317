import React from "react";
import "./App.css";
import { Authentication, Home } from "./pages";
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/auth" element={<Authentication />} />
      </Routes>
    </>
  );
};

export default App;
