import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allMedications: [],
  ismedication: "",
};

const medicationReducer = createSlice({
  name: "medication",
  initialState,
  reducers: {
    addMedication: (state, action) => {
      if (state.allMedications !== null && state.allMedications !== undefined) {
        state.allMedications = [...state.allMedications, action.payload];
      } else {
        state.allMedications = [action.payload];
      }
    },
    removeMedication: (state, action) => {
      state.allMedications = state.allMedications.filter(
        (medication) => medication !== action.payload
      );
    },
    SetIsmedication: (state, action) => {
      state.ismedication = action.payload;
    },
  },
});

export const { addMedication, removeMedication, SetIsmedication } =
  medicationReducer.actions;

export const allMedications = (state) => state.medication.allMedications;
export const ismedication = (state) => state.medication.ismedication;

export default medicationReducer.reducer;

/**
 
**Documentation: Medication Reducer

The medicationReducer is a slice of the Redux store that manages data related to medications. 
It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Dependencies:
@reduxjs/toolkit: A package that provides utilities and conventions for working with Redux.


**Initial State:
The initialState defines the initial values for the medicationReducer slice. It includes two properties:

* allMedications: An array that holds all the medications. Initially set to an empty array.
* ismedication: A property that holds information about the medication status. It is initially set to an empty string.

**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following actions are defined:

* addMedication: An action that adds a medication to the allMedications array. If the array is empty or not yet defined, 
  it initializes the array with the payload value.
* removeMedication: An action that removes a medication from the allMedications array, based on the payload value.
* SetIsmedication: An action that sets the ismedication property in the state to the payload value.

**Action Creators Export:
The action creators (addMedication, removeMedication, SetIsmedication) are extracted from the medicationReducer.actions 
object and exported for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selectors are defined:

* allMedications: Retrieves the allMedications array from the medicationReducer slice of the state.
* ismedication: Retrieves the ismedication value from the medicationReducer slice of the state.

**Reducer Export:
The generated reducer function (medicationReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to medication data in the Redux store.


 */
