import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftSquare } from "react-icons/bs";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { Loading } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";
import { alldiseases } from "../../redux/reducers/diseaseReducer";
import { allAllergies } from "../../redux/reducers/allergyReducer";
import {
  allMedications,
  ismedication,
} from "../../redux/reducers/medicationReducer";
import Medicalproblems from "./Medicalproblems";
import Medications from "./Medications";
import Allergy from "./Allergy";
import Femaleclient from "./Femaleclient";
import {
  SetAccident,
  metaccident,
  havechildhoodillness,
  SetChildhoodillness,
} from "../../redux/reducers/medicalhistoryReducer";

const MedicalHistory = () => {
  const navigate = useNavigate();
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUserid = useSelector(userId);
  const currentUser = useSelector(user);
  const medications = useSelector(allMedications);
  const dispatch = useDispatch();
  const diseases = useSelector(alldiseases);
  const allergies = useSelector(allAllergies);
  const medicationselected = useSelector(ismedication);
  const [documentid, setDocumentid] = useState("");
  const [updateddata, setUpdateddata] = useState([]);
  const [selectedallergic, setSelectedallergic] = useState([]);
  const [selectedmedicalproblems, setSelectedmedicalproblems] = useState([]);
  const [selectedfemalecondition, setSelectedfemalecondition] = useState([]);
  const currentaccident = useSelector(metaccident);
  const currentchildhoodillness = useSelector(havechildhoodillness);
  const [accident, setAccident] = useState(currentaccident);
  const [childhoodillness, setChildhoodillness] = useState(
    currentchildhoodillness
  );

  useEffect(() => {
    const fetchHistory = async () => {
      const historyRef = collection(db, "ConsentForms");
      const queryRef = query(
        historyRef,
        where("consentFormType", "==", "HealthHistoryForm")
      );
      onSnapshot(queryRef, (querySnapshot) => {
        setLoading(true);
        querySnapshot.forEach((doc) => {
          const historyinfo = doc.data();
          setRecord(historyinfo);
        });
      });
    };
    setTimeout(() => {
      fetchHistory();
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      const docRef = collection(db, "users/userProfiles/HealthHistory");
      const q = query(docRef, where("userID", "==", currentUserid));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          setDocumentid(id);
          setUpdateddata(data);
        });
      });
    };

    fetchDocument();
  }, [documentid]);

  // -------------------------------ADD THE SELECTED DATA TO FIRESTORE ONCE AND UPDATE THE NEXT TIME------------------------

  const handleSave = async () => {
    // creates a document and we are using the id from it
    if (currentUser.healthForm === false) {
      const document = doc(collection(db, "users/userProfiles/HealthHistory"));
      setDoc(doc(db, "users/userProfiles/HealthHistory", document.id), {
        createdAt: serverTimestamp(),
        userEmail: currentUser.email,
        userName: currentUser.name,
        userID: currentUserid,
        healthFormId: document.id,
        healthhistory: {
          diseases: diseases,
          allergic: allergies,
          medications: {
            ismedicated: medicationselected,
            medications: medications,
          },
          femalecondition: {
            question: record.HealthQuestions.Question3.heading,
            answer: selectedfemalecondition,
          },
          accident: {
            question: record.HealthQuestions.Question4.heading,
            answer: accident,
          },
          childhoodillness: {
            question: record.HealthQuestions.Question5.heading,
            answer: childhoodillness,
          },
        },
      })
        .then(() => {
          console.log("successfully added to the database");
        })
        .catch((err) => err.message);

      // update health form as true
      const updateUserInfo = doc(
        db,
        "/users/userProfiles/AllUsers",
        currentUserid
      );
      await updateDoc(updateUserInfo, {
        healthForm: true,
      })
        .then(() => {
          console.log("successfully updated the health form state");
        })
        .catch((Err) => Err.message);
      navigate("/");
    }

    if (currentUser.healthForm === true) {
      const updateHealthinfo = doc(
        db,
        "users/userProfiles/HealthHistory",
        documentid
      );

      await updateDoc(updateHealthinfo, {
        healthhistory: {
          diseases: diseases,
          allergic: allergies,
          medications: {
            ismedicated: medicationselected,
            medications: medications,
          },
          femalecondition: {
            question: record.HealthQuestions.Question3.heading,
            answer: selectedfemalecondition,
          },
          accident: {
            question: record.HealthQuestions.Question4.heading,
            answer: accident,
          },
          childhoodillness: {
            question: record.HealthQuestions.Question5.heading,
            answer: childhoodillness,
          },
        },
      })
        .then(() => {
          console.log("sucessfully updated the health history");
        })
        .catch(() => {
          console.log("no document found in the database");
        });
      navigate("/healthhistory");
    }
    dispatch(SetAccident(accident));
    dispatch(SetChildhoodillness(childhoodillness));
  };

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="md:bg-secondary-bg md:p-6 mt-10 rounded-sm text-gray-700  md:mx-5 mb-5 grid gap-4">
        <div className="flex items-center justify-between">
          <div>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className=" text-gray-700 capitalize text-2xl font-medium"
            >
              <BsArrowLeftSquare />
            </button>
          </div>
          <div className="flex gap-2">
            <div
              onClick={handleSave}
              className="text-white bg-primary-color font-bold cursor-pointer w-fit
                  px-6 py-2
                  "
            >
              Save
            </div>
          </div>
        </div>

        {/* MEDICAL HISTORY */}
        {loading ? (
          <div className="grid gap-4">
            <div className="text-center grid gap-2">
              <div className="font-bold  text-lg">{record.name}</div>
            </div>

            <Medicalproblems
              heading={record.HealthDisease.heading}
              disease={record.HealthDisease.diseases}
              selectedmedicalproblems={selectedmedicalproblems}
              setSelectedmedicalproblems={setSelectedmedicalproblems}
            />

            <Medications
              heading={record.HealthQuestions.Question1.heading}
              medication={record.HealthQuestions.Question1.value}
            />

            <Allergy
              heading={record.HealthQuestions.Question2.heading}
              allergy={record.HealthQuestions.Question2.value}
              selectedallergic={selectedallergic}
              setSelectedallergic={setSelectedallergic}
            />

            <Femaleclient
              heading={record.HealthQuestions.Question3.heading1}
              subheading={record.HealthQuestions.Question3.heading}
              value={record.HealthQuestions.Question3.value}
              selectedfemalecondition={selectedfemalecondition}
              setSelectedfemalecondition={setSelectedfemalecondition}
            />
            <div className="bg-white shadow rounded-sm p-4 grid gap-4">
              <span className="font-semibold text-sm md:text-base flex gap-2">
                {record.HealthQuestions.Question4.heading}
              </span>
              <div className="border-b-1 border-primary-color  w-full">
                <input
                  type="text"
                  value={accident}
                  placeholder="If any , write here"
                  onChange={(e) => setAccident(e.target.value)}
                  className="w-full border-none focus:ring-0"
                />
              </div>
            </div>
            <div className="bg-white shadow rounded-sm p-4 grid gap-4">
              <span className="font-semibold text-sm md:text-base flex gap-2">
                {record.HealthQuestions.Question5.heading}
              </span>
              <div className="border-b-1 border-primary-color  w-full">
                <input
                  type="text"
                  value={childhoodillness}
                  placeholder="If any , write here"
                  onChange={(e) => setChildhoodillness(e.target.value)}
                  className="w-full border-none focus:ring-0"
                />
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default MedicalHistory;

/**
 ** Documentation: MedicalHistory Component

The MedicalHistory component is responsible for displaying and managing the user's medical history form. It allows users to select and update their medical problems, medications, allergies, female conditions, accidents, and childhood illnesses.

**Redux State:

userId: This state variable is obtained using a Redux selector and contains the current user's ID.
user: This state variable is obtained using a Redux selector and contains the current user's information.
allMedications: This state variable is obtained using a Redux selector and contains a list of all available medications.
ismedication: This state variable is obtained using a Redux selector and contains the selected medication (Yes or No).
alldiseases: This state variable is obtained using a Redux selector and contains a list of all available medical problems (diseases).
allAllergies: This state variable is obtained using a Redux selector and contains a list of all available allergies.
metaccident: This state variable is obtained using a Redux selector and contains the current value of the accident field in the medical history form.
havechildhoodillness: This state variable is obtained using a Redux selector and contains the current value of the childhood illness field in the medical history form.


**State Variables:

record: A state variable to store the fetched data of the medical history form from the database.
loading: A state variable to track whether data is loading from the database.
documentid: A state variable to store the document ID of the user's health history form in the database.
updateddata: A state variable to store the user's health history data fetched from the database.
selectedallergic: A state variable to store the user's selected allergies.
selectedmedicalproblems: A state variable to store the user's selected medical problems (diseases).
selectedfemalecondition: A state variable to store the user's selected female conditions.
accident: A state variable to store the user's input for the accident field in the medical history form.
childhoodillness: A state variable to store the user's input for the childhood illness field in the medical history form.

**Functions:

handleSave: A function that handles saving the user's medical history form data to the database. If the user has not filled the medical history form before, it creates a new document and saves the data. If the user has previously filled the form, it updates the existing document with the updated data.

**Component Structure:

The component renders a header with a back button and a save button.
The component displays the medical history form fields, including medical problems (diseases), medications, allergies, female conditions, accidents, and childhood illnesses.
Each field is managed by a separate component, such as Medicalproblems, Medications, Allergy, and Femaleclient, which handle the selection and display of data.
The component fetches the data for the medical history form from the database using the record state and sets the state variables accordingly.
The component updates the medical history form data in real-time as the user selects or inputs information.
When the user clicks the save button, the component saves the data to the database and updates the corresponding Redux states.

**Usage:
The MedicalHistory component is used to allow users to fill out their medical history form. It fetches the pre-existing medical problems, medications, allergies, female conditions, accidents, and childhood illnesses from the database and allows users to select or input their medical history related to each field. The component updates the user's medical history in real-time and saves it to the database when the user clicks the save button.
 */
