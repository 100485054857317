import React from "react";
import { motion } from "framer-motion";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCopy } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Share = ({ setIsClicked }) => {
  const currentUser = useSelector(user);
  const currentUserID = useSelector(userId);
  // const shareText = `Hi I am ${currentUser.name} sharing my referral code: \`${currentUserID}\` , Open an account with Bitcare using my referral code to get discounts.`;
  const shareUrl = "https://gobit.care/auth";

  const shareText = `
Hi there,

Sign up for our Premium MedSpa and Wellness Services and receive a fantastic $50 discount Card.

I'm ${currentUser.name} , and here is my referral code: ${currentUserID}, Open a Bitcare account using it, and you'll get an instant $50 Discount Card.
 
Here are the steps to claim your reward:

1. Sign up to Bitcare using this link - https://gobit.care/auth .
2. Enter the referral code \`${currentUserID}\` during registration.
3. Visit the Referral page to redeem your gift card .
4. Claim your discount at the Bitcare Medspa and Wellness center

Don't miss out on this great offer!
  `;

  const handleCopy = () => {
    toast.success(`Referral Code ${currentUserID} copied`, {
      position: "top-right",
      theme: "light",
      toastId: "referralcopied",
    });
  };

  return (
    <motion.div
      initial={{ y: "-100%" }}
      animate={{ y: 0 }}
      exit={{ y: "-100%" }}
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.4,
      }}
      className="fixed inset-0 flex justify-center items-center text-gray-700 z-[10001] bg-[rgba(0,0,0,0.8)]"
    >
      <div className="bg-white lg:w-[600px] w-[90%] rounded-sm shadow ">
        <div className="flex justify-end p-4">
          <AiOutlineCloseSquare
            onClick={() => setIsClicked(false)}
            className="text-3xl cursor-pointer"
          />
        </div>
        <Divider />

        <span className="text-sm font-bold p-4">Share</span>
        <div className="flex flex-wrap gap-4 p-4 justify-center">
          <EmailShareButton
            // url={shareUrl}
            url=""
            subject="Claim Your $50 Card with My Bitcare Referral Code"
            body={shareText}
          >
            <EmailIcon size={40} className="cursor-pointer" />
          </EmailShareButton>
          <FacebookShareButton url={shareUrl} quote={shareText}>
            <FacebookIcon size={40} className="cursor-pointer" />
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl} title={shareText}>
            <WhatsappIcon size={40} className="cursor-pointer" />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl} title={shareText}>
            <TwitterIcon size={40} className="cursor-pointer" />
          </TwitterShareButton>
        </div>
        <Divider />
        <div className="p-4 flex gap-4 items-center justify-center">
          <span className="font-semibold text-lg">Your Referral Code</span>
          <CopyToClipboard text={currentUserID} onCopy={handleCopy}>
            <span className="text-sm font-semibold cursor-pointer border-2 border-gray-500 rounded-lg p-2 flex gap-2 mt-4 md:mt-0 items-center w-fit">
              <p>
                <AiOutlineCopy size={24} />
              </p>
              {currentUserID}
            </span>
          </CopyToClipboard>
        </div>
      </div>
    </motion.div>
  );
};

export default Share;

/**
 * *Share Component Overview:
The Share component is a modal that allows users to share referral information via
 email, Facebook, Twitter, and WhatsApp. It utilizes the react-share library to provide the sharing options 
 and relies on Redux to access the current user's information.

**Dependencies:
React: The core library for building UI components.
framer-motion: A library for creating smooth animations in React components.
react-share: A library that provides social media sharing buttons and icons.

**Props:
setIsClicked: A function to control the state of the modal and close it when needed. It is passed as a prop from the parent component.

**State:
The Share component does not have any internal state.

**Redux Usage:
The component utilizes Redux to access the current user's information. The following selectors are used:

1. user: Selects the current user's information from the Redux store, such as name and image.
2. userId: Selects the current user's ID from the Redux store, which is used in the referral code.

**Functions:
handleShareButtonClick: This function is not defined in the Share component itself but is expected to be defined in the parent component. It sets the isClicked state to true when the user clicks the "Share Referral" button.

**UI Components and Libraries:
motion.div: A component from the framer-motion library, used to create animations for the modal's entrance, exit, and transitions.
Divider: A component from the @mui/material library, used to render a horizontal divider line in the modal.
EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton: Components from the react-share library, providing sharing options for different social media platforms.
EmailIcon, FacebookIcon, WhatsappIcon, TwitterIcon: Icons from the react-share library, used as icons for the respective sharing buttons.
AiOutlineCloseSquare: An icon from the react-icons/ai library, used as a close button for the modal.

**Share Modal:
The modal displays social media sharing buttons (email, Facebook, Twitter, and WhatsApp) with the user's referral information. 
When the user clicks on a sharing button, it allows them to share the referral information using the selected platform.
 */
