import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import selectedReducer from "./reducers/selectedReducer";
import diseaseReducer from "./reducers/diseaseReducer";
import medicationReducer from "./reducers/medicationReducer";
import allergyReducer from "./reducers/allergyReducer";
import medicalhistoryReducer from "./reducers/medicalhistoryReducer";
import medicalrecordReducer from "./reducers/medicalrecordReducer";

const rootReducer = combineReducers({
  user: userReducer,
  selected: selectedReducer,
  allergy: allergyReducer,
  disease: diseaseReducer,
  medication: medicationReducer,
  medicalhistory: medicalhistoryReducer,
  medicalrecord: medicalrecordReducer,
});

export default rootReducer;

/**
**Documentation: Root Reducer
The rootReducer is a combined reducer that combines all the individual reducers into a single, top-level reducer. 
This root reducer will be used to create the Redux store, which manages the overall state of your application.

**Dependencies:
. @reduxjs/toolkit: A package that provides utilities and conventions for working with Redux.

**Reducers:
The rootReducer combines multiple individual reducers into one. 
Each reducer handles a specific slice of the application's state:

1. userReducer: Manages user-related data and authentication state.
2. selectedReducer: Manages selected items or data in the application.
3. allergyReducer: Manages data related to allergies.
4. diseaseReducer: Manages data related to diseases or medical conditions.
5. medicationReducer: Manages data related to medications.
6. medicalhistoryReducer: Manages data related to medical history.
7. medicalrecordReducer: Manages data related to medical records.

**CombineReducers:
The combineReducers function from @reduxjs/toolkit is used to combine these individual reducers into a single root reducer. 
Each individual reducer handles a specific slice of the application's state. When actions are dispatched, the corresponding reducer processes the action and updates its slice of the state.

**Root Reducer Export:
The combined rootReducer is exported, and it will be used to create the Redux store in the main application.

**/
