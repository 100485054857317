import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alldiseases: [],
};

const diseaseReducer = createSlice({
  name: "disease",
  initialState,
  reducers: {
    addDisease: (state, action) => {
      if (state.alldiseases !== null && state.alldiseases !== undefined) {
        state.alldiseases = [...state.alldiseases, action.payload];
      } else {
        state.alldiseases = [action.payload];
      }
    },
    removeDisease: (state, action) => {
      state.alldiseases = state.alldiseases.filter(
        (disease) => disease !== action.payload
      );
    },
  },
});

export const { addDisease, removeDisease } = diseaseReducer.actions;

export const alldiseases = (state) => state.disease.alldiseases;

export default diseaseReducer.reducer;

/** 
 * 
 ** Documentation: Disease Reducer

The diseaseReducer is a slice of the Redux store that manages data related to diseases or medical conditions. 
It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Initial State:
The initialState defines the initial values for the diseaseReducer slice. It includes one property:

* alldiseases: An array that holds various diseases or medical conditions. Initially set to an empty array.

**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object.
 The following actions are defined:

* addDisease: An action that adds a disease or medical condition to the alldiseases array. If the array is empty or not yet
   defined, it initializes the array with the payload value.
* removeDisease: An action that removes a disease or medical condition from the alldiseases array, based on the payload value.

**Action Creators Export:
The action creators (addDisease and removeDisease) are extracted from the diseaseReducer.actions object and exported 
for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selector is defined:
* alldiseases: Retrieves the alldiseases array from the diseaseReducer slice of the state.

**Reducer Export:
The generated reducer function (diseaseReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to disease or medical condition data in the Redux store.
 
*/
