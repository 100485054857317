import React, { useState, useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { db } from "../../firebase";
import {
  updateDoc,
  doc,
  collection,
  setDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";

const FamilyMember = () => {
  const [loading, setLoading] = useState(false);
  const currentuser = useSelector(user);
  const [memberList, setMemberList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchFamilyData = () => {
    const memberRef = collection(db, "users/GuestUser/AllUsers");
    const queryRef = query(
      memberRef,
      where("ownerEmail", "==", currentuser.email)
    );
    onSnapshot(queryRef, (querySnapshot) => {
      // setLoading(true);
      querySnapshot.forEach((doc) => {
        const userinfo = doc.data();
        console.log(userinfo);
      });

      // setMemberList(userinfo);
    });
  };

  useEffect(() => {
    fetchFamilyData();
  });
  console.log("Members: " + memberList);

  const showDetail = (memberId) => {
    setShowModal(true);
    const selected = memberList.find((member) => member.id === memberId);
    setShowModal(selected);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  console.log("This is family member page");
  return (
    <div className="mt-20 md:mt-10 mx-3 mb-3">
      {/* top section */}

      <div className="flex items-center justify-between p-1 md:m-2">
        <span className=" text-gray-700 capitalize md:text-2xl font-medium text-lg">
          Family Members
        </span>
        <div className="flex text-gray-700 gap-4">
          <NavLink
            to="/addmember"
            className="bg-[#C0DCDD] p-2 flex items-center gap-2 rounded-sm transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-[#a1ccce] duration-300"
          >
            <span>Add Members</span>
            <AiOutlineEdit />
          </NavLink>
        </div>
      </div>

      <div className="bg-secondary-bg rounded-sm mb-5 p-5">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 p-5 max-h-full gap-6">
          {memberList.map((member) => (
            <div className="bg-white rounded-sm w-100 sm:w-full">
              <div className="p-5">
                <div
                  className="flex flex-col items-center justify-center mt-2 mb-4"
                  key={member.id}
                >
                  <img
                    src="../../assets/member.jpeg"
                    alt="default"
                    className="w-[150px] h-[150px] object-cover rounded-full"
                  />
                  <h1 className="text-primary-color text-2xl my-4 font-semibold">
                    {member.name}
                  </h1>
                  <button
                    className="bg-button-bg text-white text-center w-full py-2 hover:cursor-pointer hover:shadow-lg"
                    onClick={() => showDetail(member.id)}
                  >
                    Show Details
                  </button>
                </div>
              </div>
            </div>
          ))}

          {showModal && (
            <>
              <div className="fixed z-1 left-0 top-0 w-full h-full bg-[#00000099] flex justify-center items-center">
                <div className="bg-white p-5 rounded-sm shadow-[0 0 10px #00000033] lg:w-[50%] max-sm:w-[90%] h-[80%] xs:w-[80%] md:w-[80%] text-justify">
                  <div className="bg-secondary-bg rounded-sm mb-5 p-5 h-full">
                    <div className="bg-white rounded-sm w-100 sm:w-full h-[100%] overflow-y-scroll p-5">
                      <button
                        type="button"
                        onClick={hideModal}
                        className="float-right border-none bg-white text-[30px] cursor-pointer"
                      >
                        &times;
                      </button>
                      <div className="flex flex-col items-center justify-between border-b mb-5 leading-5">
                        <img
                          src="../../assets/image.jpeg"
                          alt="default"
                          className="w-[150px] h-[150px] object-cover rounded-full"
                        />
                        <h1 className="text-primary-color text-2xl my-4 font-semibold">
                          {showModal.name}
                        </h1>
                      </div>

                      <div>
                        <div className="grid gap-2 grid-cols-2 mb-3">
                          <div>Email:</div>
                          <div className="text-justify">{showModal.email}</div>
                        </div>
                        <div className="grid gap-2 grid-cols-2 mb-3">
                          <div>Phone Number:</div>
                          <div>{showModal.phone}</div>
                        </div>
                        <div className="grid gap-2 grid-cols-2 mb-3">
                          <div>Gender:</div>
                          <div>{showModal.gender}</div>
                        </div>
                        <div className="grid gap-2 grid-cols-2 mb-3">
                          <div>DOB:</div>
                          <div>{showModal.dob}</div>
                        </div>
                        <div className="grid gap-2 grid-cols-2 mb-3">
                          <div>Age:</div>
                          <div>{showModal.age}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FamilyMember;
