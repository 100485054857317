import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import "./Authentication.css";

const Authentication = () => {
  const [isNewUser, setNewUser] = useState(false);

  return (
    <div className="h-screen lg:p-6 md:p-4 bg-main-bg">
      <div className="banner bg-cover bg-center h-full md:rounded-md grid lg:grid-cols-2">
        <div className="flex justify-center items-center p-2">
          <div
            className="bg-[#FFFFFF] rounded-md md:shadow-lg flex justify-center xl:pt-0
            xl:h-[710px] xl:w-[500px] lg:h-[640px] lg:w-[450px] md:h-[650px] md:w-[450px] 
            sm:h-[500px] sm:w-[350px] h-[100%] w-[90%] max-w-[600px] 
          "
          >
            {isNewUser ? (
              <Register setNewUser={setNewUser} />
            ) : (
              <Login setNewUser={setNewUser} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;

/**
 ** Documentation: Authentication Component

The Authentication component is responsible for rendering the login and registration forms in the authentication screen.
 It allows users to switch between the login and registration forms using a toggle state.

**State:
isNewUser: A state variable that determines whether the user is a new user or an existing user. It is initialized to false, indicating that the user is an existing user (login form).

**Component Structure:

1.The component renders a background banner with a login and registration form side by side.
2.The isNewUser state variable is used to conditionally render either the Register component or the Login component based on the user's choice.
3.If isNewUser is true, the Register component is displayed, allowing new users to register.
4.If isNewUser is false, the Login component is displayed, allowing existing users to log in.
5.Both the Register and Login components are enclosed in a container with a white background, rounded corners, and a drop shadow to provide a visually appealing appearance.
6.The banner and container are styled to be responsive, adjusting their size based on the screen size.

**Usage:
The Authentication component is used as the main entry point for the authentication screen. 
It renders the login and registration forms side by side. By default, the login form is displayed (isNewUser is false). 
If a user clicks on the "Register" link or button, the isNewUser state is updated to true, and the registration form is shown. 
Likewise, clicking on the "Login" link or button sets isNewUser back to false, and the login form is displayed again.
 */
