import React, { useState } from "react";
import { Divider } from "@mui/material";
import { useStateContext } from "../../contexts/ContextProvider";
import { IoAddSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllergy,
  removeAllergy,
  allAllergies,
} from "../../redux/reducers/allergyReducer";

const Allergy = ({
  heading,
  allergy,
  selectedallergic,
  setSelectedallergic,
}) => {
  const { activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const allergies = useSelector(allAllergies);

  // -----------------------------LOGIC FOR SELECTING ALLERGICS------------------------------------------------

  const handleAllergic = (event) => {
    const { value, checked } = event.target;
    // if (checked) {
    //   setSelectedallergic([...selectedallergic, value]);
    //   if (selectedallergic.includes("Other")) {
    //     setInput("");
    //   }
    // } else {
    //   setSelectedallergic(
    //     selectedallergic.filter((problems) => problems !== value)
    //   );
    // }
    if (checked) {
      dispatch(addAllergy(value));
    } else {
      dispatch(removeAllergy(value));
    }
  };
  return (
    <div className="bg-white rounded-sm shadow">
      <div className="font-semibold p-2 md:p-4 text-sm md:text-base">
        {heading}
      </div>
      <Divider />
      <div
        className={`p-2 md:p-4 grid gap-4 xl:grid-cols-3 md:grid-cols-2  lg:grid-cols-2
     ${activeMenu ? "md:grid-cols-1 " : ""}
     `}
      >
        {allergy?.map((item) => (
          <div key={item.id}>
            <label className="block font-medium">
              <input
                type="checkbox"
                value={item.name}
                checked={allergies?.includes(item.name)}
                onChange={handleAllergic}
                className="mr-2  focus:ring-0 border-gray-700 text-primary-color focus:ring-offset-0"
              />
              <span className="text-sm md:text-base">{item.name}</span>
            </label>
          </div>
        ))}
      </div>
      {selectedallergic?.includes("Other") && (
        <div className="p-2 md:p-4">
          <form className="flex gap-2">
            <div className="border-b-1 border-primary-color  w-full">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="w-full border-none focus:ring-0"
              />
            </div>
            <button
              type="submit"
              className="rounded-sm p-2 font-medium cursor-pointer text-center bg-primary-bg text-white text-sm md:text-base"
            >
              <IoAddSharp className="text-2xl" />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Allergy;

/**
 * 
 **Documentation: Allergy Component
The Allergy component is responsible for displaying and managing the allergy-related fields in the medical history form.
 It allows users to select allergies from the available options and optionally add their own custom allergy 
 if "Other" is selected.

**Redux State:

allAllergies: This state variable is obtained using a Redux selector and contains a list of all available allergy options.


**Props:
heading: The main heading for the allergy-related section.
allergy: An array of objects containing the available allergy options.
selectedallergic: A state variable received as a prop that stores the user's selected allergies.
setSelectedallergic: A function received as a prop to update the selected allergies.

**State Variables:

input: A state variable that holds the user's input when they want to add a custom allergy.

**Function:

handleAllergic: A function that handles the selection and update of allergies. When a user selects or deselects an allergy, it dispatches appropriate Redux actions to add or remove the selected allergy from the state. If the "Other" option is selected, the function allows users to add their own custom allergy by setting the input state variable.

**Component Structure:

1.The component renders a header with the main heading for the allergy-related section.
2.The component displays a list of available allergy options as checkboxes.
3.Each checkbox represents an allergy from the allergy prop.
4.When a user selects or deselects an allergy, the handleAllergic function is called to update the selected allergies in the Redux state.
5. If the "Other" option is selected, an input field is shown that allows users to add their own custom allergy.

**Usage:
The Allergy component is used to display and manage the allergy-related section in the medical history form. It receives the available allergy options through the allergy prop and allows users to select or deselect their allergies using checkboxes. If the "Other" option is selected, users can enter their own custom allergy using the input field. When a user selects or deselects an allergy or adds a custom allergy, the component updates the selected allergies in the Redux state.
 */
