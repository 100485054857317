import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { db } from "../../firebase";
import { updateDoc, doc, collection, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, Loading } from "../../components";

function AddMember() {
  const currentuser = useSelector(user);
  const currentuserId = useSelector(userId);

  const [dob, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [memberPhone, setMemberPhone] = useState(0);
  const [memberGender, setMemberGender] = useState("");
  const [memberRelationship, setMemberRelationship] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const navigate = useNavigate();

  const memberDetailsCollectionRef = doc(
    collection(db, "users/GuestUser/AllUsers")
  );

  const gotonextpage = () => {
    navigate("/booking");
  };

  const handleDOBChange = (e) => {
    const inputDOB = e.target.value;
    const birthDate = new Date(inputDOB);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = Math.floor(
      ageInMilliseconds / (365 * 24 * 60 * 60 * 1000)
    );

    setDOB(inputDOB);
    setAge(ageInYears);
  };

  useEffect(() => {
    if (alertMessage !== "") {
      alert(alertMessage);
      setAlertMessage("");
    }
  }, [alertMessage]);

  console.log("This is add member page");
  //Add the data
  const onSubmitMember = async () => {
    if(age >= 16){
        try {
            await setDoc(memberDetailsCollectionRef, {
              name: memberName,
              email: memberEmail,
              phone: memberPhone,
              dob: dob,
              gender: memberGender,
              relationship: memberRelationship,
              age: age,
              userId: memberDetailsCollectionRef.id,
              ownerId: currentuserId,
              ownerName: currentuser.name,
              ownerEmail: currentuser.email,
            });
            setMemberGender("");
            setAlertMessage("Member added Successfully!");
            console.log("Data Uploaded Successfully!");
          } catch (err) {
            console.error(err);
          }
    }else{
        alert("Age of the member must be greater than 16 !!")
    }
    
  };
  return (
    <div>
      <div className="mt-20 md:mt-10 mx-3 mb-3">
      <Header title="ADD MEMBER" Button={true} />
       
        <div className="bg-secondary-bg rounded-sm mb-5 p-5">
          <div className="flex justify-center p-5 max-h-full">
            <div className="bg-white rounded-sm w-3/5">
              <div className="p-5">
                <div className="mb-3">
                  <label
                    for="fname"
                    className="text-primary-color block text-sm font-medium"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fname"
                    className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                    name="name"
                    placeholder="Enter your name"
                    required
                    onChange={(e) => setMemberName(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="email"
                    className="text-primary-color block text-sm font-medium"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                    name="email"
                    placeholder="Enter your email"
                    required
                    onChange={(e) => setMemberEmail(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="phone"
                    className="text-primary-color block text-sm font-medium"
                  >
                    Phone
                  </label>
                  <input
                    type="number"
                    id="phone"
                    className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                    placeholder="Enter your phone"
                    required
                    onChange={(e) => setMemberPhone(Number(e.target.value))}
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="relationship"
                    className="text-primary-color block text-sm font-medium"
                  >
                    RelationShip
                  </label>
                  <input
                    type="text"
                    id="relationship"
                    className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                    placeholder="Enter your RelationShip"
                    required
                    onChange={(e) => setMemberRelationship(e.target.value)}
                  />
                </div>

                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-3">
                    <label
                      for="gender"
                      className="text-primary-color block text-sm font-medium"
                    >
                      Gender
                    </label>
                    <select
                      id="gender"
                      className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                      onChange={(e) => setMemberGender(e.target.value)}
                    >
                      <option value="">Select Gender...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label
                      for="dob"
                      className="text-primary-color block text-sm font-medium"
                    >
                      DOB
                    </label>
                    <input
                      type="date"
                      id="dob"
                      className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                      value={dob}
                      onChange={handleDOBChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="age"
                      className="text-primary-color block text-sm font-medium"
                    >
                      Age
                    </label>
                    <input
                      type="typw"
                      id="age"
                      className="w-full py-2 px-4 my-2 inline-block border border-solid border-gray-300 box-border"
                      value={age}
                      required
                    />
                  </div>
                </div>

                <div className="text-right">
                  <button
                    className="border bg-primary-color text-white p-3"
                    onClick={onSubmitMember}
                  >
                    Add Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMember;
