import React, { useState } from "react";
import { Header, Empty } from "../../components";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect } from "react";
import { db } from "../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { userId } from "../../redux/reducers/userReducer";
import { Loading } from "../../components";
import { SetMedicalrecord } from "../../redux/reducers/medicalrecordReducer";

const MedicalRecord = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const [record, setRecord] = useState([]);
  const [found, setFound] = useState(false);
  const currentUserid = useSelector(userId);

  useEffect(() => {
    const fetchupdatedhistory = async () => {
      const docRef = collection(db, "users/userProfiles/HealthHistory");
      const q = query(docRef, where("userID", "==", currentUserid));
      onSnapshot(q, (querySnapshot) => {
        setLoading(true);
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setRecord(data);
          dispatch(SetMedicalrecord(data));
          setFound(true);
        });
      });
    };
    setTimeout(() => {
      fetchupdatedhistory();
    }, 1000);
  }, []);

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <Header title="Medical History" />
      <div className="md:bg-secondary-bg md:p-6  mt-4 rounded-sm text-gray-700  md:mx-5 mb-5">
        {loading ? (
          <div className="bg-white shadow rounded-sm  p-2">
            <div className="flex justify-end">
              <div
                onClick={() => navigate("/record")}
                className="text-white bg-primary-color font-bold text-base cursor-pointer w-fit
                px-6 py-2
              "
              >
                Edit
              </div>
            </div>
            {found ? (
              <div>
                {/* medical problems */}
                {record.healthhistory.diseases?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                        ${activeMenu ? "" : "md:flex"}
                      `}
                    >
                      <div className="font-bold ">Medical problems :</div>
                      <span className="font-medium ">
                        {record.healthhistory.diseases?.join(", ")}
                      </span>
                    </div>
                    <Divider />
                  </>
                )}
                {/* allergic */}
                {record.healthhistory.allergic?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                      ${activeMenu ? "" : "md:flex"}
                    `}
                    >
                      <div className="font-bold ">Allergic :</div>
                      <span className="font-medium ">
                        {record.healthhistory.allergic?.join(", ")}
                      </span>
                    </div>
                    <Divider />
                  </>
                )}

                {/* medication  */}
                {record.healthhistory.medications.medications?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                              ${activeMenu ? "" : "md:flex"}
                            `}
                    >
                      <div className="font-bold ">Medications :</div>
                      {record.healthhistory.medications.medications?.join(", ")}
                    </div>
                    <Divider />
                  </>
                )}
                {/* female condition */}
                {record.healthhistory.femalecondition.answer?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                            ${activeMenu ? "" : "md:flex"}
                          `}
                    >
                      <div className="font-bold ">
                        (Female Clients){" "}
                        {record.healthhistory.femalecondition.question} :
                      </div>
                      {record.healthhistory.femalecondition.answer?.join(", ")}
                    </div>
                    <Divider />
                  </>
                )}
                {/* accident */}
                {record.healthhistory.accident.answer?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                            ${activeMenu ? "" : "md:flex"}
                          `}
                    >
                      <div className="font-bold ">
                        {record.healthhistory.accident.question}
                      </div>
                      {record.healthhistory.accident.answer}
                    </div>
                    <Divider />
                  </>
                )}
                {/* childhood illness */}
                {record.healthhistory.childhoodillness.answer?.length !== 0 && (
                  <>
                    <div
                      className={` items-center gap-2 lg:flex  p-4
                            ${activeMenu ? "" : "md:flex"}
                          `}
                    >
                      <div className="font-bold ">
                        {record.healthhistory.childhoodillness.question}
                      </div>
                      {record.healthhistory.childhoodillness.answer}
                    </div>
                    <Divider />
                  </>
                )}
              </div>
            ) : (
              <div>
                <Empty title="medical" />
              </div>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default MedicalRecord;

/**
 **Documentation: MedicalRecord Component
The MedicalRecord component is responsible for displaying the medical history of a user.
 It fetches the medical history data from Firestore based on the current user's ID and displays it in a structured format.

**Redux State:
medicalrecord: This state variable is obtained using a Redux selector and contains the medical history data of the current user.

**State Variables:
loading: A boolean state variable that indicates whether the data is still loading or not.
found: A boolean state variable that is set to true if the medical record is found for the current user.

**Functions:
fetchupdatedhistory: An asynchronous function that fetches the medical history data from Firestore using the current user's ID. It updates the record, medicalrecord, and found state variables.

**Component Structure:
1. The component renders a header with the title "Medical History" using the Header component.
2.The main content of the component is inside a container with rounded corners and a light gray background.
  It uses the Loading component to show a loading spinner when data is still loading.
3. When the data is loaded, the component displays the medical history information in a structured format.
 It shows details such as medical problems, allergies, medications, female-related conditions, accidents, and childhood illnesses.
4. If no medical record is found for the current user, the component displays an "Empty" message using the Empty component.

**Usage:
The MedicalRecord component is used to display the medical history of a user in the application. It retrieves the relevant data from Firestore using the current user's ID and presents it in a readable format. The component can be used on a user's profile or medical history page.
 */
