import React, { useRef, useState, useEffect } from "react";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";
import Loading from "../Loading";
import { BrowserMultiFormatReader } from "@zxing/library";
import Swal from "sweetalert2";

const RewardScanner = ({ setOpen, reward, visitedTimes }) => {
  const [error, setError] = useState("");
  const currentUserID = useSelector(userId);
  const currentUser = useSelector(user);

  const [loading, setLoading] = useState(true);

  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();
  let isScanning = false;

  useEffect(() => {
    const startScanner = async () => {
      isScanning = true;
      try {
        const result = await codeReader.decodeOnceFromVideoDevice(
          undefined,
          videoRef.current
        );
        const scannedUrl = result.getText();
        const expectedUrl = "https://gobitcare.com/";
        // change the QR CODE url here..
        if (scannedUrl === expectedUrl) {
          console.log("Success! Scanned URL matches expected URL.");
          // Display success message or perform desired actions

          setLoading(false);
          const rewardRef = doc(db, "/Bitcare_UserRewards", currentUserID);
          const docsnapshot = await getDoc(rewardRef); // Await added here
          if (docsnapshot.exists()) {
            if (rewardRef && reward !== null) {
              await updateDoc(rewardRef, {
                // Await added here
                visitedTimes: visitedTimes + 1,
                scannedTime: serverTimestamp(),
              }).then(() => {
                setOpen(false);
                setLoading(true);
                Swal.fire({
                  title: "Success!",
                  text: "Congratulations! QR code scanned successfully.",
                  icon: "success",
                  confirmButtonText: "Close",
                  confirmButtonColor: "#116C6E",
                });
              });
            }
          } else {
            if (rewardRef && reward !== null) {
              await setDoc(rewardRef, {
                // Await added here
                createdAt: serverTimestamp(),
                scannedTime: serverTimestamp(),
                email: currentUser.email,
                name: currentUser.name,
                visitedTimes: 1,
              }).then(() => {
                setOpen(false);
                setLoading(true);
              });
            }
          }
        } else {
          setError("Please Scan a valid QR Code");
          isScanning = false;
        }
      } catch (err) {
        console.log("errror while accessing camera :", err);
        isScanning = false;
      }
    };

    if (!isScanning) {
      startScanner();
    }

    // Clean up the codeReader when the component unmounts
    return () => {
      codeReader.reset();
    };
  }, []);

  const stopScan = () => {
    isScanning = false;
    setOpen(false);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center text-gray-700 z-[10000001] bg-[rgba(0,0,0,0.8)]">
      <div className="bg-white lg:w-[600px] w-[90%] rounded-sm shadow-sm p-4">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Reward Scanner</span>
          <span
            className="bg-gray-300 px-4 py-1  rounded-sm cursor-pointer"
            onClick={stopScan} // Call stopScan() on close button click
          >
            close
          </span>
        </div>
        <div className="flex justify-center mt-4 mb-4 font-bold uppercase">
          <span className="w-fit">Scan the QR Code</span>
        </div>
        {error ? (
          <h4 className="text-center">{error}</h4>
        ) : (
          <>
            {loading ? (
              <div className="flex justify-center w-full">
                <video
                  ref={videoRef}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            ) : (
              <Loading />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RewardScanner;

/**
 * 
**RewardScanner Component Overview:
The RewardScanner component is used to scan QR codes and perform actions based on the scanned code.
 It utilizes the @zxing/library library to read QR codes from the user's camera and interacts with 
 Firebase Firestore to update the user's reward information.

**Props:
* setOpen: A function to control the state of the RewardScanner modal and close it when needed. It is passed as a prop from the parent component.
* reward: The reward information that will be updated in Firebase when a valid QR code is scanned.
* visitedTimes: The number of times the user has visited the reward page.

**State:
* error: A state to store an error message if an invalid QR code is scanned.
* loading: A state to manage the loading state when scanning is in progress.

**Dependencies:
* React: The core library for building UI components.
* react-redux: A library to connect the component with the Redux store and access the user's information.
* jsQR: A library to decode QR codes from images.
* firebase/firestore: Firebase Firestore library for interacting with the database.
* Loading: A custom component (not provided in the code snippet) used to display a loading animation.

**Hooks and Refs:
* useRef: Used to create a reference to the canvas element for QR code decoding.
* useState: Used to manage the error and loading state.
* useEffect: Used to handle the initial setup for QR code scanning and cleanup.
* useSelector: Used to access the current user's information from the Redux store.

**Variables and Constants:
* canvasRef: A ref to the canvas element for QR code decoding.
* currentUserID: A variable storing the current user's ID obtained from the Redux store.
* currentUser: A variable storing the current user's information obtained from the Redux store.
* videoStream: A variable to store the video stream from the user's camera.
* videoRef: A ref to the video element used to display the camera feed.
* codeReader: An instance of the BrowserMultiFormatReader from @zxing/library, used for QR code scanning.
* isScanning: A boolean variable to track if the QR code scanner is actively scanning.

**Functions:
* startScanner: A function that initiates the QR code scanning process. It uses the BrowserMultiFormatReader to decode the QR code from the video feed and performs actions based on the scanned code. It updates the reward information in Firebase when a valid QR code is scanned and shows an error message for invalid codes.
* stopScan: A function called when the user clicks the "close" button. It stops the QR code scanning and closes the RewardScanner modal.

**QR Code Scanning:
The useEffect hook is used to initialize the QR code scanning process. 
The camera feed is accessed using the BrowserMultiFormatReader, 
and when a QR code is successfully scanned and matches the expected URL, 
the user's reward information in Firebase is updated. If the QR code does not match the expected URL, 
an error message is displayed.
 */
