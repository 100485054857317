import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useStateContext } from "../../contexts/ContextProvider";
import { Header, Loading } from "../../components";
import { useSelector } from "react-redux";
import { membership } from "../../redux/reducers/selectedReducer";
import { Divider } from "@mui/material";
import { GoPrimitiveDot } from "react-icons/go";

const Singlemembership = () => {
  const [card, setCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const { activeMenu } = useStateContext();
  const currentmembership = useSelector(membership);

  // Fetch membership details based on name
  useEffect(() => {
    const fetchmembership = async () => {
      const memberRef = collection(db, "Membership");
      const queryRef = query(memberRef, where("name", "==", currentmembership));
      onSnapshot(queryRef, (querySnapshot) => {
        setLoading(true);
        querySnapshot.forEach((doc) => {
          const userinfo = doc.data();
          setCard(userinfo);
        });
      });
    };
    setTimeout(() => {
      fetchmembership();
    }, 1000);
  }, []);

  // Function to check if the card has benefits then show this
  const benefits = card.benefits?.map((benefit) => (
    <div
      key={benefit}
      className="text-sm font-semibold flex items-center gap-1"
    >
      <span>
        <GoPrimitiveDot className="text-green-600" />
      </span>
      {benefit}
    </div>
  ));

  // Function to check if the card has included then show this

  const included = card.included?.map((included) => (
    <div
      key={included}
      className="text-sm font-semibold flex items-center gap-1"
    >
      <span>
        <GoPrimitiveDot className="text-green-600" />
      </span>
      <span> {included}</span>
    </div>
  ));

  // Function to check if the card has desc then show this

  const description = card.desc?.map((desc, index) => (
    <div key={index} className="grid gap-2 mt-2">
      <div className="font-bold text-sm">{desc.title}</div>
      <div className="text-sm font-medium font-membership">
        {desc.description}
      </div>
    </div>
  ));

  const desclength = card.desc?.length;
  const include = card.included?.length;

  return (
    <div className="mx-3 mt-20 md:mt-10">
      <Header Button={true} />
      <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm mb-5 text-gray-700 flex items-center justify-center">
        {loading ? (
          <div
            className={`flex flex-col gap-6 bg-white shadow p-3 xl:w-1/2  font-membership ${
              activeMenu ? "lg:w-full" : "lg:w-[70%] "
            }`}
          >
            <div className="rounded-sm flex justify-center items-center">
              <img
                src={card.image}
                alt="card_image"
                className="object-cover w-full rounded-sm h-[250px] md:h-[300px]"
              />
            </div>
            <div className="bg-secondary-bg rounded-sm flex flex-col gap-4 p-4">
              <div className="flex items-center justify-between">
                <span className="text-3xl font-semibold">{card.name}</span>
                <span className="font-bold text-lg flex items-center">
                  {/* <span className="text-xl mr-1">$</span> */}
                  {card.price}
                </span>
              </div>
              <Divider sx={{ borderStyle: "dotted" }} />

              {/* tag */}
              <div className="text-sm font-medium">{card.tag}</div>
              <Divider sx={{ borderStyle: "dotted" }} />

              {/* membership benefits */}
              <div>
                <span className="font-bold text-lg">Membership Benefits</span>
                <p className="grid gap-4 mt-4">{benefits}</p>
              </div>
              <Divider sx={{ borderStyle: "dotted" }} />

              {/* treatment included */}
              {include !== 1 ? (
                <>
                  <div className="flex flex-col gap-1">
                    <span className="font-bold text-lg">Treatments</span>
                    <span className="mt-2 font-medium">
                      Includes the following treatment
                    </span>
                    <div className="grid gap-4 mt-4">{included}</div>
                  </div>
                  <Divider sx={{ borderStyle: "dotted" }} />
                </>
              ) : null}

              {/* treatment description */}
              {desclength !== 0 ? (
                <div>
                  <span className="font-bold text-lg">
                    Treatment Descriptions
                  </span>
                  <div className="mt-2">{description}</div>
                </div>
              ) : null}

              {/* button */}
              <a
                target="_blank"
                href={card.link}
                className="bg-button-bg py-2 px-4 rounded-sm text-white text-center mt-4 cursor-pointer hover:shadow-xl"
              >
                Join Now
              </a>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Singlemembership;

/**
 **Documentation: Singlemembership Component

The Singlemembership component represents a page that displays detailed information about a selected membership 
from the list of memberships offered by the Bitcare Medspa and Wellness center. 
It fetches the information for the selected membership from the Firebase collection Membership 
based on the currentmembership obtained from the Redux store. 
The component displays the membership name, price, image, tag, benefits, included treatments, and treatment descriptions.

**Dependencies:
react: The core library for building user interfaces in React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-router-dom: A routing library for React applications.
react-redux: A state management library for React applications.
@mui/material: A set of components for React that implement Material Design.
react-icons/go: A set of icons from the Go Programming Language.

**State Variables:
card: An object state variable that stores the detailed information of the selected membership fetched from the Firebase collection Membership.
loading: A boolean state variable that determines whether the membership information is being loaded or not.
activeMenu: A value obtained from the global application state using the useStateContext hook.
currentmembership: The selected membership name obtained from the Redux store using the useSelector hook.

**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch detailed membership information from the Firebase collection Membership based on the currentmembership on component mount.

**Functions:
1. benefits: A function that generates JSX elements for displaying the membership benefits with green dot icons.
2. included: A function that generates JSX elements for displaying the included treatments with green dot icons.
3. description: A function that generates JSX elements for displaying the treatment descriptions with their titles and descriptions.
4. desclength: A variable that stores the number of treatment descriptions.
5. include: A variable that stores the number of included treatments.

**Component Structure:

1. The component fetches detailed membership information from the Firebase collection Membership 
  using the useEffect hook with the onSnapshot function based on the currentmembership.
2. The fetched membership information is stored in the card state object, and the loading state is set to true 
  to indicate that the data has been loaded.
3.The component renders a section with detailed information about the selected membership, 
  including the membership name, price, image, tag, benefits, included treatments, and treatment descriptions.
4.The component uses Material-UI styles and CSS classes to style various elements,
   such as the membership card, membership name, price, image, tag, benefits, included treatments, and treatment descriptions.

**Rendered Output:
The component renders a page that displays detailed information about the selected membership from the list of memberships offered 
by the Bitcare Medspa and Wellness center. 
The displayed information includes the membership name, price, image, tag, benefits with green dot icons, 
included treatments with green dot icons, and treatment descriptions with their titles and descriptions.
 */
