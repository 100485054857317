import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Divider } from "@mui/material";
import {
  addFemalecondition,
  allfemalecondition,
  removeFemalecondition,
} from "../../redux/reducers/medicalhistoryReducer";
import { useDispatch, useSelector } from "react-redux";

const Femaleclient = ({
  heading,
  subheading,
  value,
  selectedfemalecondition,
  setSelectedfemalecondition,
}) => {
  const { activeMenu } = useStateContext();
  const femaleconditon = useSelector(allfemalecondition);
  const dispatch = useDispatch();

  const handleFemalecondition = (event) => {
    const { value, checked } = event.target;
    // if (checked) {
    //   setSelectedfemalecondition([...selectedfemalecondition, value]);
    // } else {
    //   setSelectedfemalecondition(
    //     selectedfemalecondition.filter((condition) => condition !== value)
    //   );
    // }
    if (checked) {
      dispatch(addFemalecondition(value));
    } else {
      dispatch(removeFemalecondition(value));
    }
  };
  return (
    <div className="bg-white rounded-sm shadow">
      <div className="font-semibold p-4 text-sm md:text-base flex gap-2">
        <span>{heading}</span>(<span>{subheading}</span>)
      </div>
      <Divider />
      <div
        className={`p-4 grid gap-4 xl:grid-cols-3 md:grid-cols-2  lg:grid-cols-2
            ${activeMenu ? "md:grid-cols-1 " : ""}
            `}
      >
        {value?.map((item) => (
          <div key={item.id}>
            <label className="block font-medium">
              <input
                type="checkbox"
                value={item.name}
                checked={femaleconditon?.includes(item.name)}
                onChange={handleFemalecondition}
                className="mr-2  focus:ring-0 border-gray-700 text-primary-color focus:ring-offset-0"
              />
              <span className="text-sm md:text-base">{item.name}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Femaleclient;

/**
 * Documentation: Femaleclient Component

The Femaleclient component is responsible for displaying and managing the female client-related fields in the medical history 
form. It allows users to select female conditions from the available options.

**Redux State:

allfemalecondition: This state variable is obtained using a Redux selector and contains a list of all
 available female conditions.
Props:

heading: The main heading for the female client-related section.
subheading: The subheading for the female client-related section.
value: An array of objects containing the available female condition options.
selectedfemalecondition: A state variable received as a prop that stores the user's selected female conditions.
setSelectedfemalecondition: A function received as a prop to update the selected female conditions.

**Helper Function:

handleFemalecondition: A function that handles the selection and update of female conditions. 
When a user selects or deselects a female condition, it dispatches appropriate Redux actions to add or remove the selected female condition from the state.

**Component Structure:

The component renders a header with the main heading and subheading for the female client-related section.
The component displays a list of available female condition options as checkboxes.
Each checkbox represents a female condition from the value prop.
When a user selects or deselects a female condition, the handleFemalecondition function is called to update the
 selected female conditions in the Redux state.

**Usage:
The Femaleclient component is used to display and manage the female client-related section in the medical history form. 
It receives the available female condition options through the value prop and allows users to select or deselect
 their female conditions using checkboxes. When a user selects or deselects a female condition, 
 the component updates the selected female conditions in the Redux state.
 * 
 */
