import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import malelogo from "../data/default-male.jpeg";
import femalelogo from "../data/default-female.jpeg";
import { useSelector } from "react-redux";
import { user } from "../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    to: "/profile",
  },
  // {
  //   label: "Family Members",
  //   to: "/familymember",
  // },
  {
    label: "Change password",
    to: "/changepassword",
  },
];

// ----------------------------------------------------------------------

export default function Userprofile() {
  const currentuser = useSelector(user);
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handlClick = (id) => {
    navigate(id);
    setOpen(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const signout = () => {
    // sessionStorage.removeItem("Auth Token");
    Cookies.remove("loggedIn");
    navigate("/auth");
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "90%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {currentuser.imageUrl ? (
          <Avatar
            alt="User_profile"
            src={currentuser.imageUrl}
            sx={{
              border: "1px solid lightgray",
            }}
          />
        ) : (
          <Avatar
            alt="User_profile"
            src={currentuser.gender === "Male" ? malelogo : femalelogo}
            sx={{ border: "2px solid lightgray" }}
          />
        )}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentuser.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {currentuser.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "line" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <div
              key={option.label}
              onClick={() => handlClick(option.to)}
              className="text-sm font-medium py-2 px-4 cursor-pointer hover:bg-light-gray rounded-sm"
            >
              {option.label}
            </div>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "line" }} />

        <div
          onClick={signout}
          className="m-2 text-md font-medium py-2 px-4 cursor-pointer bg-button-bg text-center text-white rounded-sm "
        >
          Logout
        </div>
      </Popover>
    </>
  );
}

/**
 * The Userprofile component is a user profile dropdown menu that displays the user's profile information and provides options for actions
 *  such as navigating to the profile, changing the password, and logging out.

**Component Structure:

1.The component imports necessary dependencies from Material-UI and React.
2.The MENU_OPTIONS array holds the menu options and their corresponding routes.
3.The Userprofile function component is defined.
4.It retrieves the current user information using the useSelector hook from Redux.
5.The component defines state variable open using the useState hook to manage the visibility of the dropdown menu.
6.The handleOpen function is defined to open the dropdown menu when the user clicks on the profile avatar icon.
7.The handlClick function is defined to handle navigation to different routes when a menu option is clicked.
8.The handleClose function is defined to close the dropdown menu.
9.The signout function is defined to handle user logout by removing the authentication token and navigating to the authentication page.
10.The component renders the profile avatar icon wrapped in an IconButton. The icon will open the dropdown menu on click.
11.The Popover component from Material-UI is used to create the dropdown menu. It displays the user's name and email, followed by the menu options, and a logout button.
12.The dropdown menu options are mapped from the MENU_OPTIONS array and rendered as clickable items.
13.The logout button is rendered, and when clicked, it calls the signout function.
 */
