import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "persist-key",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const persistedStore = persistStore(store);

export default store;
export { persistedStore };

/**

**Documentation: Redux Store Configuration

The code provided in this file is responsible for configuring and creating the Redux store for your application. 
It includes additional features such as Redux-Persist to persist the store's state in the browser's storage.

**Dependencies:
. @reduxjs/toolkit: A package that provides utilities and conventions for working with Redux.
. redux-thunk: A middleware that allows you to write async logic that interacts with the Redux store.
. rootReducer: The combined root reducer that contains all the reducers of the application.
. redux-persist and redux-persist/lib/storage: Packages that enable state persistence for the Redux store, 
  using browser's storage (localStorage for web).

**Redux-Persist Configuration:
 A configuration object (persistConfig) is created to customize the behavior of Redux-Persist. 
 It specifies a key (persist-key) under which the persisted state will be stored in the browser's storage (localStorage).

**Persisted Reducer:
The persistReducer function takes the persistConfig and the root reducer (rootReducer) as inputs
 and returns a new reducer (persistedReducer). The persistedReducer is used in the Redux store configuration, 
 so the store's state can be persisted.

**Redux Store Creation:

. configureStore function is used to create the Redux store with the following options:
. reducer: The persisted reducer (persistedReducer) that includes the application's combined reducers.
. middleware: An array containing middleware functions. Here, thunk middleware is used to enable asynchronous actions in Redux.

**Persisted Store:
The persistStore function is called with the Redux store (store) to create the persisted store (persistedStore). 
The persisted store keeps track of the stored state and enables the retrieval of the persisted state during the application's initialization.

**Exports:
The store is exported as the main Redux store, which holds the application's state.
The persistedStore is exported separately, enabling Redux-Persist to work with the store.


 **/
