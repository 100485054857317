import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const Medspaservicecard = ({ image, name }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        key={name}
        onClick={() =>
          navigate("/medspaservice", {
            state: {
              service: name,
            },
          })
        }
        className={`bg-white p-4 cursor-pointer shadow-sm rounded-md
        hover:shadow-lg
      `}
      >
        <div className="h-min overflow-hidden">
          <LazyLoadImage
            src={image}
            alt="service-image"
            loading="lazy"
            className="w-full md:h-[180px] h-[150px] object-cover object-top rounded-sm border-secondary-color hover:scale-125 transition-all duration-500"
          />
        </div>
        <div className={`mt-2 grid gap-2 bg-secondary-bg rounded-sm py-2 px-4`}>
          <div className="text-xl font-bold ">{name}</div>
        </div>
      </div>
    </div>
  );
};

export default Medspaservicecard;

/**
 *The Medspaservicecard component is a card component used to display information about a specific medspa service. 
 It includes an image, service name, and a link to navigate to the details of the service.

**Component Behavior:

1.The component receives the image and name props as input.
2.The useNavigate hook from the react-router-dom library is used to enable navigation to the details page of the service.
3.The LazyLoadImage component from the react-lazy-load-image-component library is used to lazily load the service image to improve performance.
4.When the user clicks on the card, it navigates to the /medspaservice route and passes the name prop as state, so the details page can access the selected service name.
 */
