import React, { useState, useEffect } from "react";
import { Header, Loading, Medspaservicecard } from "../../components";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useStateContext } from "../../contexts/ContextProvider";
import { IoSearch } from "react-icons/io5";

const Alltreatments = () => {
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { activeMenu } = useStateContext();

  useEffect(() => {
    const fetchservices = async () => {
      const serviceRef = collection(db, "MedSpa/MedSpaServices/AllServices");

      await getDocs(serviceRef)
        .then((response) => {
          setLoading(true);
          const servicedata = response.docs.map((doc) => ({
            ...doc.data(),
          }));
          const filteredArray = servicedata.filter((item) =>
            item.hasOwnProperty("image")
          );

          setService(filteredArray);
        })
        .catch((err) => console.log(err.message));
    };

    setTimeout(() => {
      fetchservices();
    }, 1000);
  }, []);

  // function that filters the treatment plan data based on the search
  const filteredService = service?.filter((obj) =>
    JSON.stringify(obj).toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <Header title="MEDSPA" Button={true} />
      <div className="bg-secondary-bg p-3 md:p-6 md:mx-5 mt-10 rounded-sm mb-5 text-gray-700">
        <div className="text-xl md:text-2xl font-semibold  text-center mb-10 ">
          All treatment Plans
        </div>
        <div className="my-2 grid place-items-center">
          <form className="border-1 flex items-center p-1 bg-white justify-between xl:w-1/2 w-full ">
            <input
              type="text"
              placeholder="search here"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="focus:ring-0 border-none flex-1  font-medium"
            />
            <IoSearch title="Filter" className="text-2xl cursor-pointer mr-2" />
          </form>
        </div>
        {/*----------------------------services------------------------------------------------ */}

        {loading ? (
          <div
            className={` md:p-2 grid gap-6  ${
              activeMenu
                ? "md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-2"
                : "xl:grid-cols-4 lg:grid-cols-3"
            }`}
          >
            {filteredService.map((item) => (
              <Medspaservicecard
                key={item.id}
                name={item.name}
                price={item.price}
                image={item.image}
              />
            ))}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Alltreatments;

/**
 
**Documentation: Alltreatments Component

The Alltreatments component represents a page that displays all treatment plans offered by the Bitcare MedSpa. 
It fetches the treatment plan data from the Firebase collection MedSpa/MedSpaServices/AllServices and
 provides a search functionality to filter the treatment plans based on user input.

**Dependencies:
react: The core library for building user interfaces in React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-icons/io5: A set of icons from the Ionicons library.

**State Variables:
service: An array state variable that stores the treatment plan data fetched from the Firebase collection MedSpa/MedSpaServices/AllServices.
filtered: An array state variable that stores the filtered treatment plan data based on user search input.
loading: A boolean state variable that determines whether the treatment plan data is being loaded or not.
search: A string state variable that stores the user input for searching treatment plans.
activeMenu: A value obtained from the global application state using the useStateContext hook.

**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch treatment plan data from the
 Firebase collection MedSpa/MedSpaServices/AllServices on component mount.

**Functions:
filteredService: A function that filters the treatment plan data based on the search state variable.

**Component Structure:
1. The component fetches treatment plan data from the Firebase collection MedSpa/MedSpaServices/AllServices 
  using the useEffect hook with the getDocs function.
2. The fetched treatment plan data is stored in the service state array, and the loading state is 
  set to true to indicate that the data has been loaded.
3. The component provides a search input field where users can type to filter the treatment plans.
4. The component renders a section that displays all the treatment plans in a grid format, 
  using the Medspaservicecard component for each treatment plan.
5. The Medspaservicecard component is used to render the name, price, and image of each treatment plan.

**Rendered Output:
The component renders a page that displays all treatment plans offered by the Bitcare MedSpa. 
The treatment plans are displayed in a grid format, with each plan showing its name, price, and an image. 
The page also provides a search input field that allows users to filter treatment plans based on their search input.
 */
