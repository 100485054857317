import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  membership: null,
  // appointment id for show details page
  appointmentId: "",
};

const selectedReducer = createSlice({
  name: "selected",
  initialState,
  reducers: {
    SetMembership: (state, action) => {
      state.membership = action.payload;
    },

    SetAppointmentId: (state, action) => {
      state.appointmentId = action.payload;
    },
  },
});

export const { SetMembership } = selectedReducer.actions;
export const membership = (state) => state.selected.membership;
export const appointmentId = (state) => state.selected.appointmentId;

export default selectedReducer.reducer;

/**
 
**Documentation: Selected Reducer

The selectedReducer is a slice of the Redux store that manages selected data, specifically related to membership 
and appointment details. It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer 
with automatically generated action creators.

**Initial State:
The initialState defines the initial values for the selectedReducer slice. It includes two properties:

* membership: Holds data related to the selected membership. Initially set to null.
* appointmentId: Holds the ID of the selected appointment for showing details. Initially set to an empty string.

**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following actions are defined:

* SetMembership: An action that sets the membership property in the state to the payload value. 
  This action is used to update the selected membership data in the store.
* SetAppointmentId: An action that sets the appointmentId property in the state to the payload value. 
  This action is used to update the selected appointment ID for showing details.

**Action Creators Export:
The action creators (SetMembership and SetAppointmentId) are extracted from the selectedReducer.actions
 object and exported for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selectors are defined:

* membership: Retrieves the membership data from the selectedReducer slice of the state.
* appointmentId: Retrieves the appointmentId value from the selectedReducer slice of the state.

**Reducer Export:
The generated reducer function (selectedReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to selected data in the Redux store, such as selected membership and appointment details.
 */
