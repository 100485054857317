import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useStateContext } from "../../contexts/ContextProvider";
import { Loading } from "../../components";

const Packages = () => {
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetech the packages list from Bitcare_Packages collection on component mount
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const collectionRef = collection(db, "Bitcare_Packages");
        await getDocs(collectionRef).then((response) => {
          setLoading(true);
          const info = response.docs.map((doc) => ({
            ...doc.data(),
          }));
          setPackages(info);
        });
      } catch (error) {
        console.log("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  // Function to navigate to singlepackage page and sent a statvalue
  const handleClick = (packageName) => {
    navigate("/package", { state: { packageName } });
  };

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="md:bg-secondary-bg md:p-6 mt-4 text-gray-700 rounded-sm md:mx-5 mb-5">
        <div className="text-2xl font-bold p-2  text-center">Our Packages</div>
        {loading ? (
          <div
            className={`grid gap-4 p-4 bg-white shadow rounded-sm ${
              activeMenu ? "lg:grid-cols-2" : "md:grid-cols-2"
            }`}
          >
            {packages?.map((item) => (
              <div
                key={item.link}
                className="bg-white border-1 shadow-sm hover:shadow-md  mt-4 md:mt-0 rounded-md"
              >
                <div>
                  <img
                    src={item.image}
                    alt=""
                    className=" w-full h-[180px] object-cover rounded-t-md"
                  />
                </div>
                <div className="grid gap-2 p-4">
                  <div className="text-xl font-bold">{item.name}</div>
                  <div className="text-md font-semibold"> $ {item.price}</div>
                  <div
                    onClick={() => handleClick(item.name)}
                    className="text-sm text-primary-color w-fit  font-bold hover:underline cursor-pointer"
                  >
                    See Package
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[280px] flex justify-center items-center bg-secondary-bg">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;

/**
 

**Documentation: Packages Component
The Packages component represents a page that displays a list of packages offered by the Bitcare Medspa and Wellness center.
 It fetches the package information from the Firebase collection Bitcare_Packages and displays them in a grid layout. 
 Each package is represented as a card with an image, package name, price, and a link to view more details.

**Dependencies:
react: The core library for building user interfaces in React applications.
react-router-dom: A routing library for React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-context: A React context library used to manage global application state.
Loading: A custom component that displays a loading indicator.

**State Variables:
packages: An array state variable that stores the package information fetched from the Firebase collection.
loading: A boolean state variable that determines whether the package information is being loaded or not.
activeMenu: Holds the current state of the active menu.

**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch package information from the Firebase collection Bitcare_Packages on component mount.

**Functions:
handleClick: A function that handles the click event when a user clicks on a package card. 
It navigates to the /package page and sends the package name as state.

**Component Structure:
1. The component fetches package information from the Firebase collection Bitcare_Packages using the useEffect hook.
2. The fetched package information is stored in the packages state array, and the loading state is set to true to indicate that the data has been loaded.
3. The component renders a section titled "Our Packages" with a header and a grid layout.
4. The grid layout displays package cards for each package with an image, package name, price, and a link to view more details.
5. Each package card has an event listener that calls the handleClick function when clicked, allowing users to view more details about the package.
6. The component uses conditional rendering to display a loading indicator if the loading state is false.

**Rendered Output:
The component renders a page that displays a list of packages offered by the Bitcare Medspa and Wellness center. 
Each package is represented as a card with an image, package name, price, and a link to view more details.
 */
