import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  allMedications,
  addMedication,
  removeMedication,
  SetIsmedication,
  ismedication,
} from "../../redux/reducers/medicationReducer";
import { IoAddSharp } from "react-icons/io5";
import { IoIosRemoveCircle } from "react-icons/io";

const Medications = ({ heading, medication }) => {
  const { activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const medications = useSelector(allMedications);
  const medicationselected = useSelector(ismedication);

  // -----------------------------LOGIC FOR MEDICATIONS (YES OR NO)------------------------------------------------

  const handleMedications = (medication) => {
    if (medicationselected === medication) {
      dispatch(SetIsmedication(""));
    } else {
      dispatch(SetIsmedication(medication));
    }
  };

  // -----------------------------LOGIC FOR ADDING MEDICTIONS------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input) return;
    dispatch(addMedication(input));
    setInput("");
  };

  const handleRemove = (item) => {
    dispatch(removeMedication(item));
  };
  // -----------------------------LOGIC FOR ADDING MEDICTIONS------------------------------------------------

  return (
    <div className="bg-white shadow rounded-sm grid gap-2">
      <div
        className={`items-center gap-4 lg:flex
    ${activeMenu ? "" : "md:flex "}
  `}
      >
        <div className="font-semibold p-4 text-sm md:text-base">{heading}</div>
        <div className="flex gap-2 p-4">
          {medication?.map((item) => (
            <div
              key={item.id}
              onClick={() => handleMedications(item.name)}
              className={`rounded-sm py-2 px-4 font-medium cursor-pointer text-center
          text-sm md:text-base 
        ${
          medicationselected === item.name
            ? "bg-primary-bg text-white"
            : "bg-secondary-bg"
        }
          `}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      {medicationselected === "Yes" && (
        <div className="font-semibold grid gap-2 p-2 md:p-4">
          <div>Please list :</div>
          <form className="flex gap-2" onSubmit={handleSubmit}>
            <div className="border-b-1 border-primary-color  w-full">
              <input
                type="text"
                value={input}
                maxLength="50"
                onChange={(e) => setInput(e.target.value)}
                className="w-full border-none focus:ring-0"
              />
            </div>
            <button
              type="submit"
              className="rounded-sm p-2 font-medium cursor-pointer text-center bg-primary-bg text-white text-sm md:text-base"
            >
              <IoAddSharp className="text-2xl" />
            </button>
          </form>
          <div className="grid gap-2 capitalize font-medium  mt-2">
            {medications?.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between hover:bg-secondary-bg py-1 px-2 "
              >
                <div className="break-all">{item}</div>
                <div onClick={() => handleRemove(item)}>
                  <IoIosRemoveCircle className=" font-medium text-2xl cursor-pointer text-red-600" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Medications;

/**
 * 
 **Documentation: Medications Component
The Medications component is a reusable component that represents a section where users can indicate whether they are taking any medications
 and list the medications they are taking.

**Props:
heading: The heading or title of the medications section.
medication: An array of objects representing the available medication options for the user to select. 
Each object has a name property that contains the name of the medication option.

**Redux State:
The component uses Redux to manage state related to medications. 
It accesses the following state variables using Redux selectors:

medications: An array containing the list of medications the user has added.
medicationselected: A string indicating whether the user has selected "Yes" or "No" to indicate if they are taking medications.

**State Variables:
input: A state variable to store the input value from the user when adding a new medication.

**Helper Functions:

handleMedications: A function that updates the medicationselected state based on the user's selection (either "Yes" or "No") to indicate if they are taking medications.
handleSubmit: A function that handles the form submission when the user adds a new medication. It dispatches the addMedication action to update the medications state.
handleRemove: A function that removes a medication from the list. It dispatches the removeMedication action to update the medications state.


**Component Structure:

The component renders a section with a heading and medication options (Yes or No) for the user to select.
When the user selects "Yes" for medications, an input field and "Add" button appear, allowing the user to add medications to the list.
The user can list medications they are taking, and each medication is displayed as a separate item with a "Remove" button to remove it from the list.
When the user selects "No" for medications, the list of medications and the input field disappear.
Styling:
The component uses CSS classes and inline styles to style various elements, such as buttons, headings, and medication items.

Usage:
The Medications component can be used in various parts of the application where the user's medications need to be managed.
 It provides a user-friendly interface for selecting medication options (Yes or No) and adding/removing medications from the list.
 */
