import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useStateContext } from "../contexts/ContextProvider";
import { Userprofile, Notifications } from ".";
import { useSelector } from "react-redux";
import { user } from "../redux/reducers/userReducer";

const NavButton = ({ customFunction, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={() => customFunction()}
    style={{ color }}
    className="relative text-xl rounded-full p-3 hover:bg-light-gray"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
);

const Navbar = () => {
  const { setActiveMenu, screenSize, setScreenSize, activeMenu } =
    useStateContext();
  const currentuser = useSelector(user);
  const [loading, setLoading] = useState(false);

  // sidebar close or open based on screen size
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //
  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
    return () => {
      setLoading(false);
    };
  }, []);

  // --------------------------------------greeting according to time-------------------------------------
  const date = new Date();
  const hours = date.getHours();
  let timeOfDay;
  if (hours < 12) {
    timeOfDay = "Good Morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "Good Afternoon";
  } else {
    timeOfDay = "Good Evening";
  }

  return (
    <div className="flex justify-between px-4 py-2 relative border-1 md:border-none">
      <div className="flex gap-3 items-center">
        <NavButton
          title="Menu"
          customFunction={() =>
            setActiveMenu((prevActiveMenu) => !prevActiveMenu)
          }
          color="#02704A"
          icon={<AiOutlineMenu />}
        />

        <div>
          {loading ? (
            <span className="text-lg md:text-xl font-bold text-gray-700 flex items-center gap-2">
              <span>{timeOfDay}</span>
              <span
                className={`hidden md:block text-primary-color capitalize ml-2  ${
                  activeMenu ? "md:hidden lg:block" : ""
                }`}
              >
                {currentuser.name}
              </span>
            </span>
          ) : (
            <div>Loading....</div>
          )}
        </div>
      </div>

      <div className="flex gap-4">
        <Notifications />
        <Userprofile />
      </div>
    </div>
  );
};

export default Navbar;

/**
 *The Navbar component is responsible for rendering the top navigation bar of the application. 
 It includes buttons for the sidebar toggle, user profile, and notifications. 
 The component also displays a greeting message according to the time of day.

**Component Behavior:

1. The screenSize state is managed to determine the current width of the screen and control the behavior of the sidebar.
2. The activeMenu state is managed to control the visibility of the sidebar based on the screen size.
3. The component sets up event listeners for window resize to update the screenSize state accordingly.
4. The currentuser is fetched from the Redux store using the useSelector hook to display the user's name in the greeting message and user profile button.
5. A greeting message is displayed based on the time of day (morning, afternoon, or evening).
6. The component displays a loading message while fetching the user's data, and then renders the actual content once the data is loaded.
 */
