import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Loading, Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import { Divider } from "@mui/material";

const PackageCard = () => {
  const location = useLocation();
  const name = location?.state.packageName;
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([]);
  const { activeMenu } = useStateContext();

  //Fetch package from the collection using the name state
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const collectionRef = collection(db, "Bitcare_Packages");
        const queryRef = query(collectionRef, where("name", "==", name));
        onSnapshot(queryRef, (querySnapshot) => {
          setLoading(true);
          querySnapshot.forEach((doc) => {
            const cardinfo = doc.data();
            setCard(cardinfo);
          });
        });
      } catch (err) {
        console.log(err);
      }
    };

    setTimeout(() => {
      fetchPackages();
    }, 1000);
  }, []);

  return (
    <div className="mx-3 mt-20 md:mt-10">
      <Header Button={true} />
      <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm mb-5 text-gray-700 flex items-center justify-center">
        {loading ? (
          <div
            className={`flex flex-col gap-6 bg-white shadow p-3 xl:w-1/2  font-membership ${
              activeMenu ? "lg:w-full" : "lg:w-[70%] "
            }`}
          >
            <div className="rounded-sm flex justify-center items-center">
              <img
                src={card.image || ""}
                alt="card_image"
                className="object-cover w-full rounded-sm h-[250px] md:h-[300px]"
              />
            </div>
            <div className="bg-secondary-bg rounded-sm flex flex-col gap-4 p-4">
              <div className="flex items-center justify-between">
                <span className="text-3xl font-semibold">{card.name}</span>
                <span className="font-bold text-lg flex items-center">
                  <span className="text-xl mr-1">$</span>
                  {card.price}
                </span>
              </div>
              <Divider sx={{ borderStyle: "dotted" }} />
              {card ? (
                <div>
                  <span className="font-bold text-lg">
                    Treatment Descriptions
                  </span>
                  <p className="mt-2">{card.description}</p>
                </div>
              ) : (
                <div>No Package selected.</div>
              )}
              <a
                href={card.link}
                target="_blank"
                className="bg-button-bg py-2 px-4 rounded-sm text-white text-center mt-4 cursor-pointer hover:shadow-xl"
              >
                Buy Now
              </a>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default PackageCard;

/**

**Documentation: PackageCard Component

The PackageCard component represents a page that displays detailed information about a specific package offered by the Bitcare Medspa and Wellness center. 
It fetches the package information from the Firebase collection Bitcare_Packages based on the package name 
received through the React Router useLocation hook. 
The fetched package information is displayed in a card format, 
including the package name, price, image, description, and a link to buy the package.

**Dependencies:
react: The core library for building user interfaces in React applications.
react-router-dom: A routing library for React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
@mui/material: A set of components for React that implement Material Design.
react-context: A React context library used to manage global application state.
Loading: A custom component that displays a loading indicator.
Header: A custom component that displays the page header.

**State Variables:
name: The package name received through the useLocation hook.
loading: A boolean state variable that determines whether the package information is being loaded or not.
card: An object state variable that stores the package information fetched from the Firebase collection based on the name.

**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch package information from the Firebase collection Bitcare_Packages based on the name received through the useLocation hook.

**Component Structure:
1. The component receives the package name from the React Router useLocation hook.
2. It fetches the package information from the Firebase collection Bitcare_Packages using the useEffect hook with a query and onSnapshot to listen for real-time updates to the data.
3.The fetched package information is stored in the card state object, and the loading state is set to true to indicate that the data has been loaded.
4. The component renders a section with a card layout that displays the package name, price, image, description, and a link to buy the package.
5. The card layout uses Material-UI Divider component to separate the treatment descriptions from the other information.
6. If the package information is still loading, the component displays a loading indicator.

**Rendered Output:
The component renders a page that displays detailed information about a specific package offered by the Bitcare Medspa and Wellness center.
 The information is displayed in a card format, including the package name, price, image, description, and a link to buy the package.

 */
