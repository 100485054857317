import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiTime } from "react-icons/bi";
import { useSelector } from "react-redux";
import { userId } from "../redux/reducers/userReducer";
import { collection, doc, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";

const Notifications = () => {
  const [open, setOpen] = useState(null);
  const currentUserID = useSelector(userId);
  const [notifications, setNotifications] = useState([]);

  // Fetch notifications from collection Bitcare_Notifications using currentUserID
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userDocRef = doc(
          collection(db, "Bitcare_Notifications"),
          currentUserID
        );
        const messagesCollectionRef = collection(userDocRef, "messages");

        const querySnapshot = await getDocs(
          messagesCollectionRef,
          orderBy("timestamp", "asc")
        );
        const messages = [];

        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            messages.push(doc.data());
          }
          setNotifications(messages);
        });

        return messages;
      } catch (err) {
        console.log("Error while fetching messages:", err);
        return [];
      }
    };

    return () => {
      fetchNotifications();
    };
  }, []);

  //Function to format timestamp to 1 min or 3 sec aga
  const formatTime = (timestamp) => {
    const formattedTimestamp = moment(timestamp.toDate()).fromNow();
    return formattedTimestamp;
  };

  const notificationCount = () => {
    if (notifications.length === 0) {
      return 0;
    } else {
      return notifications.length;
    }
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge color="error" badgeContent={notificationCount()}>
          <IoMdNotificationsOutline className="text-primary-color" />
        </Badge>
      </IconButton>

      {/* open Pop over */}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClick={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1">Notifications</Typography>
          {notifications.length === 0 && (
            <Typography variant="subtitle2">no notification yet</Typography>
          )}
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        {notifications?.map((item, index) => (
          <div
            key={index}
            className="mt-4 mb-4 p-2 flex gap-4 items-center text-gray-700 hover:bg-gray-200 m-1 cursor-pointer"
          >
            <Avatar />
            <div className="flex gap-2 flex-col">
              <span className="text-sm font-bold">{item.title}</span>
              <span className="text-sm font-medium">{item.body}</span>
              <span className="flex gap-2 items-center text-xs font-medium">
                <BiTime />
                <p>{formatTime(item.timestamp)}</p>
              </span>
            </div>
          </div>
        ))}
      </Popover>
    </>
  );
};

export default Notifications;

/**
 * The Notifications component is responsible for displaying notifications for the current user. It uses Firebase Firestore to fetch and display the user's notifications in a popover when the notifications icon is clicked.

**Component Behavior:

1. The component uses the userId state from the Redux store to determine the current user's ID for querying notifications.
2.It fetches the user's notifications from the Firestore collection Bitcare_Notifications using the currentUserID.
3.The notifications are ordered by the timestamp field in ascending order to display the latest notifications first.
4.The component calculates the total count of notifications and displays it as a badge on the notifications icon using the notificationCount function.
5.When the notifications icon is clicked, a popover is opened, displaying the notifications.
6.Each notification is represented as a list item, showing the notification title, body, and the time elapsed since the notification was created.
7.If there are no notifications, a message "no notification yet" is displayed.
 */
