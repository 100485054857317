import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBG4JnVt9HIIirTu-4RTOtUT1sGtTrEKgY",
  authDomain: "bitcare-auth.firebaseapp.com",
  databaseURL: "https://bitcare-auth-default-rtdb.firebaseio.com",
  projectId: "bitcare-auth",
  storageBucket: "bitcare-auth.appspot.com",
  messagingSenderId: "957385368559",
  appId: "1:957385368559:web:383bb407e769c0a8d1cc1a",
  measurementId: "G-19JWT89DED",
};

// initialize firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// initialize firestore
export const db = getFirestore(app);

// initalize storage
export const storage = getStorage(app);

// initalize realtimedatabase

export const database = getDatabase(app);

// initalize messaging
export const messaging = getMessaging(app);
