import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  medicalrecord: [],
};

const medicalrecordReducer = createSlice({
  name: "medicalrecord",
  initialState,
  reducers: {
    SetMedicalrecord: (state, action) => {
      state.medicalrecord = action.payload;
    },
  },
});

export const { SetMedicalrecord } = medicalrecordReducer.actions;
export const medicalrecord = (state) => state.medicalrecord.medicalrecord;

export default medicalrecordReducer.reducer;

/**
 **Documentation: Medicalrecord Reducer

The medicalrecordReducer is a slice of the Redux store that manages data related to medical records. 
It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Initial State:
The initialState defines the initial values for the medicalrecordReducer slice. It includes one property:

* medicalrecord: An array that holds the medical record data. Initially set to an empty array.

**Reducer Actions:
* The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following action is defined:

* SetMedicalrecord: An action that sets the medicalrecord property in the state to the payload value. 
This action is used to update the medical record data in the store.

**Action Creators Export:
The action creator (SetMedicalrecord) is extracted from the medicalrecordReducer.actions object and 
exported for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selector is defined:

* medicalrecord: Retrieves the medicalrecord array from the medicalrecordReducer slice of the state.

**Reducer Export:
The generated reducer function (medicalrecordReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to medical record data in the Redux store.
 
 */
