import React, { useState, useEffect } from "react";
import { Header, Loading } from "../../components";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useStateContext } from "../../contexts/ContextProvider";

const Medspaservice = () => {
  const location = useLocation();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const { activeMenu } = useStateContext();
  const [isReadMore, setIsReadMore] = useState(true);
  const navigate = useNavigate();

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const gotonextpage = () => {
    navigate("/booking");
  };

  useEffect(() => {
    const fetchservice = async () => {
      const serviceRef = collection(db, "MedSpa/MedSpaServices/AllServices");
      let selectedservice = location.state.service;
      const queryRef = query(serviceRef, where("name", "==", selectedservice));

      onSnapshot(queryRef, (snapshot) => {
        setLoading(true);
        snapshot.forEach((doc) => {
          const data = doc.data();
          setService(data);
        });
      });
    };
    setTimeout(() => {
      fetchservice();
    }, 1000);
  }, []);

  const included = service.included?.map((item) => (
    <div key={item} className="text-sm font-semibold">
      {item}
    </div>
  ));

  const description = service.desc?.length;

  return (
    <div className="mt-20 md:mt-10 mx-3 ">
      <Header title="MEDSPA" Button={true} />
      <div className="bg-secondary-bg p-3 md:p-6 md:mx-5 mt-10 rounded-sm  mb-5 text-gray-700 flex items-center justify-center">
        {loading ? (
          <div
            className={`flex flex-col gap-6 bg-white p-3 xl:w-1/2  ${
              activeMenu ? "lg:w-full " : "lg:w-1/2"
            }`}
          >
            <div className="rounded-sm">
              <img
                src={service.image}
                alt="service_image"
                className="object-cover rounded-sm h-[300px]"
              />
            </div>
            <div className="bg-secondary-bg rounded-sm flex flex-col gap-4 p-4">
              <div className="flex items-center justify-between">
                <div className="grid gap-2">
                  <span className="text-3xl font-semibold">{service.name}</span>
                </div>
              </div>
              <Divider sx={{ borderStyle: "dotted" }} />
              <div className="text-sm font-light">{service.tag}</div>
              <Divider sx={{ borderStyle: "dotted" }} />

              <div className="">
                <div className="text-xl font-medium text-primary-color">
                  What's Included
                </div>
                <div className="grid gap-4 mt-4">{included}</div>
              </div>
              <Divider sx={{ borderStyle: "dotted" }} />

              {description !== 0 ? (
                <div>
                  <div className="text-xl font-medium text-primary-color">
                    Treatment Details
                  </div>
                  <div className="mt-4 text-sm font-medium ">
                    {isReadMore ? service.desc?.slice(0, 120) : service.desc}
                    <span onClick={toggleReadMore}>
                      {isReadMore ? (
                        <span className="cursor-pointer text-primary-color font-bold">
                          ...read more
                        </span>
                      ) : (
                        <span className="cursor-pointer text-primary-color font-bold">
                          ...show less
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              ) : null}

              <div
                onClick={gotonextpage}
                className="bg-button-bg py-2 px-4 rounded-sm text-white text-center mt-4 cursor-pointer hover:shadow-xl"
              >
                Next
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Medspaservice;

/**
 * 
 **Documentation: Medspaservice Component

The Medspaservice component represents a page that displays details of a specific service offered by the Bitcare MedSpa.
 It fetches the service data from the Firebase collection MedSpa/MedSpaServices/AllServices based 
 on the selected service passed as state from the previous page (using React Router's useLocation hook).
 The component provides a "read more" and "read less" functionality to show a truncated description and
allows the user to toggle between the two views. Additionally, there is a "Next" button that navigates
 to the "booking" page when clicked.

**Dependencies:
react: The core library for building user interfaces in React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-router-dom: A routing library for React applications.
@mui/material: A set of React components for Material-UI styles.

**State Variables:
service: An object state variable that stores the details of the selected service fetched from the Firebase collection.
loading: A boolean state variable that determines whether the service data is being loaded or not.
activeMenu: A value obtained from the global application state using the useStateContext hook.
isReadMore: A boolean state variable that toggles the "read more" and "read less" functionality for the description.
location: An object obtained from React Router's useLocation hook.

**Hooks:

useEffect: A hook that runs side effects in functional components. It is used to fetch service data from the Firebase collection MedSpa/MedSpaServices/AllServices based on the selected service in the location object.

**Functions:
toggleReadMore: A function that toggles the isReadMore state variable to switch between showing a truncated description and the full description of the service.
gotonextpage: A function that navigates to the "booking" page when the "Next" button is clicked.

**Component Structure:

1. The component retrieves the selected service from the location.state.service object (passed as state from the previous page).
2. The component fetches the details of the selected service from the Firebase collection MedSpa/MedSpaServices/AllServices using
   the useEffect hook with the onSnapshot function.
3. The fetched service details are stored in the service state object, and the loading state is set to true 
  to indicate that the data has been loaded.
4.The component provides a "read more" and "read less" functionality to toggle between a truncated and
   full description of the service.
5. The component renders a section that displays the details of the selected service, including its name,
   image, tag, included items, and description.
6. The component renders a "Next" button that allows users to navigate to the "booking" page.

**Rendered Output:
The component renders a page that displays the details of a specific service offered by the Bitcare MedSpa. The page shows the service's name, image, tag, included items, and a truncated description by default. When the user clicks the "read more" link, the full description is shown, and clicking "read less" will show the truncated description again. The page also provides a "Next" button that allows users to navigate to the "booking" page.
 */
