import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { Header, Loading } from "../../components";
import { useDispatch } from "react-redux";
import { SetMembership } from "../../redux/reducers/selectedReducer";

const Membership = () => {
  const [card, setCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const { activeMenu } = useStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch membership from the firebase collection
  useEffect(() => {
    const fetchmembership = async () => {
      const memberRef = collection(db, "Membership");
      await getDocs(memberRef).then((response) => {
        setLoading(true);
        const info = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setCard(info);
      });
    };
    setTimeout(() => {
      fetchmembership();
    }, 1000);
  }, []);

  const handleClick = (name) => {
    navigate("/singlemembership");
    dispatch(SetMembership(name));
  };

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <Header title="Membership" />
      <div className="md:bg-secondary-bg md:p-6 mt-4 text-gray-700 rounded-sm md:mx-5 mb-5">
        <div className="text-2xl font-bold p-2  text-center">
          Our Memberships
        </div>
        <div className="text-center m-2 font-bold text-sm">
          Exclusive Monthly Offers & Benefits at your fingertips, with up to 15%
          off certain products and priority scheduling
        </div>
        {loading ? (
          <>
            {card && (
              <div
                className={`grid gap-4 p-4 bg-white shadow rounded-sm ${
                  activeMenu ? "lg:grid-cols-2" : "md:grid-cols-2"
                }`}
              >
                {card.map((item) => (
                  <div
                    key={item.id}
                    className="bg-white border-1 shadow-sm hover:shadow-md  mt-4 md:mt-0 rounded-md"
                  >
                    <div>
                      <img
                        src={item.data.image}
                        alt=""
                        className=" w-full h-[180px] object-cover rounded-t-md"
                      />
                    </div>
                    <div className="grid gap-2 p-4">
                      <div className="text-xl font-bold">{item.data.name}</div>
                      <div className="text-md font-semibold">
                        {item.data.price}
                      </div>
                      <div
                        onClick={() => handleClick(item.data.name)}
                        className="text-sm text-primary-color w-fit  font-bold hover:underline cursor-pointer"
                      >
                        See benefits
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="h-[280px] flex justify-center items-center bg-secondary-bg">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default Membership;

/**
 
**Documentation: Membership Component
The Membership component represents a page that displays a list of memberships offered by the Bitcare Medspa and Wellness center.
 It fetches the membership information from the Firebase collection Membership and displays the membership name, price, image, and a link to see the benefits of each membership.

**Dependencies:
react: The core library for building user interfaces in React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-router-dom: A routing library for React applications.
react-redux: A state management library for React applications.
@mui/material: A set of components for React that implement Material Design.
react-context: A React context library used to manage global application state.
Header: A custom component that displays the page header.
Loading: A custom component that displays a loading indicator.
SetMembership: A Redux action creator used to set the selected membership in the Redux store.

**State Variables:
card: An array state variable that stores the membership information fetched from the Firebase collection Membership. 
Each membership object includes the membership data and an id to uniquely identify the membership.
loading: A boolean state variable that determines whether the membership information is being loaded or not.
activeMenu: A value obtained from the global application state using the useStateContext hook.


**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch membership information from the Firebase collection Membership on component mount.
useNavigate: A hook from React Router that provides the ability to navigate programmatically to other pages.
useDispatch: A hook from React Redux that returns the Redux store's dispatch function, used to dispatch actions to the store.

**Functions:
handleClick: A function that is called when a user clicks on a membership card. It navigates to the "/singlemembership" page 
and dispatches the SetMembership action to set the selected membership in the Redux store.

**Component Structure:
1. The component fetches membership information from the Firebase collection Membership using the useEffect hook with the getDocs function.
2. The fetched membership information is stored in the card state array, and the loading state is set to true to indicate that the data has been loaded.
3. The component renders a section with a list of membership cards. Each card displays the membership name, price, image, and a link to see the benefits of the membership.
4. The component uses Material-UI styles and CSS classes to style various elements, such as the membership card, membership name, price, image, and link to see benefits.

**Rendered Output:
The component renders a page that displays a list of memberships offered by the Bitcare Medspa and Wellness center.
 Each membership is displayed in a card format, including the membership name, price, image, and a link to see the benefits of the membership.

 */
