//This is Virtual Consultation page
import React, { useState } from "react";
// import "../VirtualConsultation/VirtualConsultation.css";
import { db } from "../../firebase";
import {
  getDocs,
  collection,
  setDoc,
  where,
  query,
  doc,
} from "firebase/firestore";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { user } from "../../redux/reducers/userReducer";

const VirtualConsultation = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);

  const [show, setShow] = useState(false);

  const [userMessage, setUserMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [backData, setBackData] = useState([]);

  const [showSymptoms, setShowSymptoms] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const currentuser = useSelector(user);

  const [service, setService] = useState([]);

  const date = new Date();

  const handleCheckboxClick = (item) => {
    if (selectedCheckboxes.includes(item)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((i) => i !== item));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, item]);
    }
  };

  console.log(selectedCheckboxes);

  const isButtonDisabled = selectedCheckboxes.length === 0;

  //Functiobn to call the body part concerns
  const fetchData = async (item) => {
    const virtualCollectionRef = query(
      collection(db, "VirtualCareServices"),
      where("title", "==", item)
    );

    try {
      setLoading(true);
      const dataConcern = await getDocs(virtualCollectionRef);
      const customerService = dataConcern.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setLoading(false);
      setService(customerService);
      setBackData(item);
      console.log("function Item: " + item);
      setShow(true);
      console.log("Inside testing service");
      console.log(customerService[0].symptom);
    } catch (err) {
      console.error(err);
    }
    // const virtualCollectionRef = query(
    //   collection(db, "VirtualCare"),
    //   where("title", "==", item)
    // );
    // try {
    //   setLoading(true);
    //   const dataConcern = await getDocs(virtualCollectionRef);
    //   const customer = dataConcern.docs.map((doc) => ({
    //     ...doc.data(),
    //     id: doc.id,
    //   }));
    //   setLoading(false);
    //   setData(customer);
    //   setBackData(item);
    //   console.log("function Item: " + item);
    //   setShow(true);
    //   console.log("Data: ");
    //   console.log(customer);
    // } catch (err) {
    //   console.error(err);
    // }
  };

  const appointmentRef = doc(collection(db, "Virtualcare_Bookings"));

  //Function to book an appointment
  const bookAppointment = async () => {
    setLoading(true);
    const id = doc(collection(db, "Virtualcare_Bookings")).id;
    await setDoc(appointmentRef, {
      booingId: id,
      bookingStatus: true,
      createdAt: date,
      userConcernArea: backData,
      userEmail: currentuser.email,
      userName: currentuser.name,
      userPhone: currentuser.phone,
      userGender: currentuser.gender,
      userAge: currentuser.age,
      userConcern: selectedCheckboxes,
      userMessage: userMessage,
    })
      .then(() => {
        alert("We'll contact you soon!");
        console.log("We'll contact you soon!");
        setShow(false);
        setShowSymptoms(false);
        setSelectedCheckboxes([]);
        setUserMessage("");
        setLoading(false);
      })
      .catch((error) => {
        alert("error!!!" + error);
        setLoading(false);
      });
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleToggle1 = () => {
    setIsChecked1(!isChecked1);
  };

  const hideModalArea1 = () => {
    setSelectedCheckboxes([]);
    setShow(false);
  };

  //Symptoms for area 1
  const handleClickSymptoms = () => {
    setShowSymptoms(true);
    // fetchService()
  };
  const hideModalSymptoms = () => {
    setShowSymptoms(false);
  };

  return (
    <div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="bg-[#f5f5f5] pb-12 pt-5 max-sm:pt-[80px]">
          <div style={{ padding: "0px 50px" }}>
            <span className="font-semibold">Virtual Consultation</span>
            <p className="text-sm font-medium">
              Please select your cosmetic concerns to receive confidential
              treatment recommendations by clicking on the appropriate body area
              on the model.
              <br /> 1. Place the Cursor on the desired Body Part (Toggle for
              front/back).
              <br />
              2. Select the symptoms which best describe your condition.
              <br />
              3. Click on submit button and see recommended services.
              <br/>
              4. Click on proceed and book your virtual consultation. {" "}
            </p>
          </div>

          <div className="text-center">
            {isChecked1 ? (
              <div>
                {isChecked ? (
                  <div
                    className="relative bg-contain left-[40%]"
                    style={{
                      backgroundImage: `url('../../assets/Maleback.png')`,
                      height: "580px",
                      width: "250px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute w-full h-full left-0 top-0">
                      <div className="absolute w-[23%] h-[15%] cursor-pointer left-[38%] top-[3%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%]" onClick={(item) => fetchData((item = "HeadBack"))}></div>
                      <div className="absolute w-[39%] h-[11%] cursor-pointer left-[29%] top-[18%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]" onClick={(item) => fetchData((item = "UpperBack"))}></div>
                      <div className="absolute w-[35%] h-[10%] cursor-pointer left-[31%] top-[29%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]" onClick={(item) => fetchData((item = "LowerBack"))}></div>
                      <div className="absolute w-2/5 h-[14%] cursor-pointer left-[29%] top-[39%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]" onClick={(item) => fetchData((item = "AbdomenBack"))}></div>
                      <div className="absolute w-[39%] h-[18%] cursor-pointer left-[29%] top-[53%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]" onClick={(item) => fetchData((item = "ThighBack"))}></div>
                      <div className="absolute w-2/5 h-[24%] cursor-pointer left-[28%] top-[71%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]" onClick={(item) => fetchData((item = "LegBack"))}></div>
                      <div className="absolute w-[11%] h-[35%] cursor-pointer left-[18%] top-[21%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%_0px_0px_50%]" onClick={(item) => fetchData((item = "HandBack"))}></div>
                      <div className="absolute w-[11%] h-[35%] cursor-pointer left-[69%] top-[21%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[0px_50%_50%_0px]" onClick={(item) => fetchData((item = "HandBack"))}></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="relative bg-contain left-[40%]"
                    style={{
                      backgroundImage: `url('../../assets/BackWomen.png')`,
                      height: "580px",
                      width: "250px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute w-full h-full left-0 top-0">
                      <div
                        className="absolute w-[30%] h-[17%] cursor-pointer left-[18%] top-[0%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%]"
                        onClick={(item) => fetchData((item = "HeadBack"))}
                      ></div>
                      <div
                        className="absolute w-[38%] h-[12%] cursor-pointer left-[13%] top-[18%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]"
                        onClick={(item) => fetchData((item = "UpperBack"))}
                      ></div>
                      <div
                        className="absolute w-[36%] h-[10%] cursor-pointer left-[13%] top-[30%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]"
                        onClick={(item) => fetchData((item = "LowerBack"))}
                      ></div>
                      <div
                        className="absolute w-[45%] h-[13%] cursor-pointer left-[8%] top-[40%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[25px_25px_0px_0px]"
                        onClick={(item) => fetchData((item = "AbdomenBack"))}
                      ></div>
                      <div
                        className="absolute w-[45%] h-[15%] cursor-pointer left-[8%] top-[53%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[0px_0px_20px_20px]"
                        onClick={(item) => fetchData((item = "ThighBack"))}
                      ></div>
                      <div
                        className="absolute w-[38%] h-[31%] cursor-pointer left-[11%] top-[68%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[15px]"
                        onClick={(item) => fetchData((item = "LegBack"))}
                      ></div>
                      <div
                        className="absolute w-[11%] h-[30%] cursor-pointer left-[1%] top-[17%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%_5px_5px_50%]"
                        onClick={(item) => fetchData((item = "HandBack"))}
                      ></div>
                      <div
                        className="absolute w-[11%] h-[30%] cursor-pointer left-[51%] top-[17%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px_50%_50%_5px]"
                        onClick={(item) => fetchData((item = "HandBack"))}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isChecked ? (
                  <div
                    className="relative bg-contain left-[40%]"
                    style={{
                      backgroundImage: `url("../../assets/Malefront.png")`,
                      height: "580px",
                      width: "250px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute w-full h-full left-0 top-0">
                      <div
                        className="absolute w-[28%] h-[16%] cursor-pointer left-[35%] top-[4%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%]"
                        onClick={(item) => fetchData((item = "Head"))}
                      ></div>
                      <div
                        className="absolute w-[37%] h-[10%] cursor-pointer left-[31%] top-[20%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]"
                        onClick={(item) => fetchData((item = "Chest"))}
                      ></div>
                      <div
                        className="absolute w-[36%] h-[10%] cursor-pointer left-[31%] top-[30%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]"
                        onClick={(item) => fetchData((item = "Stomach"))}
                      ></div>
                      <div
                        className="absolute w-[40%] h-[14%] cursor-pointer left-[29%] top-[40%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]"
                        onClick={(item) => fetchData((item = "Abdomen"))}
                      ></div>
                      <div
                        className="absolute w-[38%] h-[18%] cursor-pointer left-[30%] top-[54%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]"
                        onClick={(item) => fetchData((item = "Thigh"))}
                      ></div>
                      <div
                        className="absolute w-[38%] h-[23%] cursor-pointer left-[30%] top-[72%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[10px]"
                        onClick={(item) => fetchData((item = "Leg"))}
                      ></div>
                      <div
                        className="absolute w-[13%] h-[35%] cursor-pointer left-[18%] top-[22%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%_0px_0px_50%]"
                        onClick={(item) => fetchData((item = "Hand"))}
                      ></div>
                      <div
                        className="absolute w-[13%] h-[35%] cursor-pointer left-[67%] top-[22%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[0px_50%_50%_0px]"
                        onClick={(item) => fetchData((item = "Hand"))}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="relative bg-contain left-[40%]"
                    style={{
                      backgroundImage: `url('../../assets/FrontWomen.png')`,
                      height: "580px",
                      width: "250px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute w-full h-full left-0 top-0">
                      <div
                        className="absolute w-[33%] h-[18%] cursor-pointer left-[15%] top-[0%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%]"
                        onClick={(item) => fetchData((item = "Head"))}
                      ></div>
                      <div
                        className="absolute w-[37%] h-[13%] cursor-pointer left-[14%] top-[18%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[20px_20px_10px_10px]"
                        onClick={(item) => fetchData((item = "Chest"))}
                      ></div>
                      <div
                        className="absolute w-[35%] h-[10%] cursor-pointer left-[15%] top-[31%] hover:bg-[rgb(93_177_225_/_50%)]"
                        onClick={(item) => fetchData((item = "Stomach"))}
                      ></div>
                      <div
                        className="absolute w-[45%] h-[12%] cursor-pointer left-[10%] top-[41%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[18px_18px_4px_4px]"
                        onClick={(item) => fetchData((item = "Abdomen"))}
                      ></div>
                      <div
                        className="absolute w-[44%] h-[16%] cursor-pointer left-[11%] top-[53%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px]"
                        onClick={(item) => fetchData((item = "Thigh"))}
                      ></div>
                      <div
                        className="absolute w-[37%] h-[30%] cursor-pointer left-[15%] top-[69%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[5px_5px_15px_15px]"
                        onClick={(item) => fetchData((item = "Leg"))}
                      ></div>
                      <div
                        className="absolute w-[11%] h-[28%] cursor-pointer left-[2%] top-[17%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[50%_0px_0px_50%]"
                        onClick={(item) => fetchData((item = "Hand"))}
                      ></div>
                      <div
                        className="absolute w-[11%] h-[28%] cursor-pointer left-[51%] top-[17%] hover:bg-[rgb(93_177_225_/_50%)] hover:rounded-[0px_50%_50%_0px]"
                        onClick={(item) => fetchData((item = "Hand"))}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-around">
            <div className="flex justify-center gap-[10px]">
              <div className="font-medium">Front</div>
              <div className="relative h-[30px] w-[60px]">
                <input
                  type="checkbox"
                  id="toggle1"
                  checked={isChecked1}
                  onClick={handleToggle1}
                  className="hidden peer"
                />
                <label
                  htmlFor="toggle1"
                  className='absolute bg-[#ccc] cursor-pointer rounded-[30px] inset-0 before:content-[""] before:absolute before:w-6 before:h-6 before:bg-[white] before:transition-[0.2s] before:rounded-[50%] before:left-[3px] before:top-[3px] peer-checked:bg-[#ccc] peer-checked:before:translate-x-[30px]'
                />
              </div>
              <div className="font-medium">Back</div>
            </div>

            <div className="flex justify-center gap-[10px]">
              <div className="font-medium">Female</div>
              <div className="relative h-[30px] w-[60px]">
                <input
                  type="checkbox"
                  id="toggle"
                  checked={isChecked}
                  onClick={handleToggle}
                  className="hidden peer"
                />
                <label
                  htmlFor="toggle"
                  className='absolute bg-[#ccc] cursor-pointer rounded-[30px] inset-0 before:content-[""] before:absolute before:w-6 before:h-6 before:bg-[white] before:transition-[0.2s] before:rounded-[50%] before:left-[3px] before:top-[3px] peer-checked:bg-[#ccc] peer-checked:before:translate-x-[30px]'
                />
              </div>
              <div className="font-medium">Male</div>
            </div>
          </div>

          <div>
            {show && (
              <div className="fixed z-[1] w-full h-full bg-[rgba(0,0,0,0.6)] flex justify-center items-center left-0 top-0">
                <div className="bg-white shadow-[0_0_10px_rgba(0,0,0,0.2)] w-[40%] h-[50%] text-justify overflow-y-auto pt-0 pb-5 px-5 rounded-[5px]">
                  <button
                    type="button"
                    onClick={hideModalArea1}
                    className="float-right border-none bg-[#ffffff] text-[27px] cursor-pointer mt-4 h-[35px] w-[35px]"
                  >
                    &times;
                  </button>
                  <div>
                    <div className="text-center text-2xl font-bold mt-5 font-body">
                      Select Symptoms
                    </div>
                    <div className="text-center text-xl font-bold mt-2.5 mb-5 font-body pb-4 border-b-1 border-black">
                      Please add the concern(s) for {backData}:
                    </div>

                    {service[0].symptoms.map((item, index) => (
                      <label
                        className="flex justify-between items-center cursor-pointer font-[20px] font-semibold text-black font-body"
                        key={index}
                      >
                        {item.concern}
                        <input
                          type="checkbox"
                          checked={selectedCheckboxes.includes(item)}
                          onChange={() => handleCheckboxClick(item)}
                          style={{ width: "20px", height: "20px" }}
                          className="accent-[#1f403d]"
                        />
                      </label>
                    ))}

                    <div className="text-center">
                      <button
                        className={
                          isButtonDisabled
                            ? "bg-[gray] text-[white] h-10 cursor-not-allowed mt-5 px-6 py-2 rounded-[20px]"
                            : "bg-[#1f403d] text-[white] cursor-pointer h-10 mt-5 mx-2.5 px-6 py-2 rounded-[20px]"
                        }
                        disabled={isButtonDisabled}
                        onClick={() => handleClickSymptoms()}
                      >
                        Submit
                      </button>
                    </div>

                    <div>
                      {showSymptoms && (
                        <>
                          <div className="fixed z-[1] w-full h-full flex justify-center items-center left-0 top-0">
                            <div className="bg-white shadow-[0_0_10px_rgba(0,0,0,0.2)] w-2/5 h-3/6 text-justify overflow-y-auto pt-0 pb-5 px-5 rounded-[5px]">
                              <div>
                                <div className="pl-5">
                                  <div className="text-center text-2xl font-bold mt-5 font-body pb-4 border-b-1 border-black">
                                    Preview Selected Symptoms
                                  </div>
                                  <br />
                                  <span className="font-semibold">
                                    Concerned Area: {backData}
                                  </span>
                                  <br />
                                  <br />

                                  <span className="font-semibold">
                                    Symptoms & Recommended Service
                                  </span>
                                  <ul className="pl-5">
                                    {selectedCheckboxes.map((item, index) => (
                                      <li key={index}>
                                        {index + 1}. {item.concern} :{" "}
                                        <a className="text-primary-color font-extrabold font-mono font-display text-[20px]" >
                                          {item.service}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>

                                  <br />

                                  <input
                                    className="w-3/5 h-[50px]"
                                    type="text"
                                    placeholder="Enter your message"
                                    required
                                    value={userMessage}
                                    onChange={(e) =>
                                      setUserMessage(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    onClick={bookAppointment}
                                    className="bg-[#1f403d] text-[white] cursor-pointer h-10 mt-5 mx-2.5 px-6 py-2 rounded-[20px]"
                                  >
                                    Proceed
                                  </button>
                                  <button
                                    type="button"
                                    onClick={hideModalSymptoms}
                                    className="bg-[#1f403d] text-[white] cursor-pointer h-10 mt-5 mx-2.5 px-6 py-2 rounded-[20px]"
                                  >
                                    Back
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualConsultation;
