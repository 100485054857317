import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAllergies: [],
};

const allergyReducer = createSlice({
  name: "allergy",
  initialState,
  reducers: {
    addAllergy: (state, action) => {
      if (state.allAllergies !== null && state.allAllergies !== undefined) {
        state.allAllergies = [...state.allAllergies, action.payload];
      } else {
        state.allAllergies = [action.payload];
      }
    },
    removeAllergy: (state, action) => {
      state.allAllergies = state.allAllergies.filter(
        (allergy) => allergy !== action.payload
      );
    },
  },
});

export const { addAllergy, removeAllergy } = allergyReducer.actions;

export const allAllergies = (state) => state.allergy.allAllergies;

export default allergyReducer.reducer;

/**
 ** Documentation: Allergy Reducer

The allergyReducer is a slice of the Redux store that manages data related to allergies. It utilizes the createSlice function 
from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Initial State:
The initialState defines the initial values for the allergyReducer slice. It includes one property:

* allAllergies: An array that holds various allergies. Initially set to an empty array.

**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following actions are defined:

* addAllergy: An action that adds an allergy to the allAllergies array. If the array is empty or not yet defined, 
it initializes the array with the payload value.
* removeAllergy: An action that removes an allergy from the allAllergies array, based on the payload value.

**Action Creators Export:
The action creators (addAllergy and removeAllergy) are extracted from the allergyReducer.actions object and exported for use 
in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selector is defined:

* allAllergies: Retrieves the allAllergies array from the allergyReducer slice of the state.

**Reducer Export:
The generated reducer function (allergyReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to allergy data in the Redux store.
 
 */
