import React from "react";
import loadingimage from "../data/loader.svg";

const Loading = () => {
  return (
    <div className="flex justify-center items-center">
      <img src={loadingimage} alt="" className="h-32" />
    </div>
  );
};

export default Loading;

/**
 * The Loading component is a simple component used to display a loading spinner or image to 
 * indicate that content is being loaded or processed.

**Component Behavior:

1. The Loading component renders a loading image (usually a spinner) centered on the screen.
2. The loadingimage variable holds the URL of the loading image (e.g., a spinner image).
3. The loading image is displayed using the img HTML element.
 */
