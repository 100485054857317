import { Divider } from "@mui/material";
import React, { useState } from "react";
import { IoAddSharp } from "react-icons/io5";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  alldiseases,
  addDisease,
  removeDisease,
} from "../../redux/reducers/diseaseReducer";

const Medicalproblems = ({
  heading,
  disease,
  selectedmedicalproblems,
  setSelectedmedicalproblems,
}) => {
  const { activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const diseases = useSelector(alldiseases);

  // -----------------------------LOGIC FOR SELECTING MEDICAL PROBLEMS------------------------------------------------
  const handleMedicalproblems = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedmedicalproblems([...selectedmedicalproblems, value]);
    } else {
      setSelectedmedicalproblems(
        selectedmedicalproblems.filter((problems) => problems !== value)
      );
    }
    if (checked) {
      dispatch(addDisease(value));
    } else {
      dispatch(removeDisease(value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input) return;
    // dispatch(addMedication(input));
    setInput("");
  };

  //

  return (
    <div className="bg-white shadow rounded-sm">
      <div className="font-semibold p-4 text-sm md:text-base">{heading}</div>
      <Divider />
      <div
        className={`p-4 grid gap-4 xl:grid-cols-3 md:grid-cols-2  lg:grid-cols-2
                ${activeMenu ? "md:grid-cols-1 " : ""}
                `}
      >
        {disease?.map((item) => (
          <div key={item.id}>
            <label className="block font-medium">
              <input
                type="checkbox"
                value={item.name}
                checked={diseases?.includes(item.name)}
                onChange={handleMedicalproblems}
                className="mr-2 focus:ring-0 border-gray-700 text-primary-color focus:ring-offset-0"
              />
              <span className="text-sm md:text-base">{item.name}</span>
            </label>
          </div>
        ))}
      </div>
      {selectedmedicalproblems?.includes("Other") && (
        <div>
          <div className="p-2 md:p-4">
            <form className="flex gap-2" onSubmit={handleSubmit}>
              <div className="border-b-1 border-primary-color  w-full">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className="w-full border-none focus:ring-0"
                />
              </div>
              <button
                type="submit"
                className="rounded-sm p-2 font-medium cursor-pointer text-center bg-primary-bg text-white text-sm md:text-base"
              >
                <IoAddSharp className="text-2xl" />
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Medicalproblems;

/**
 * 
 ** Documentation: Medicalproblems Component

The Medicalproblems component is responsible for displaying a list of medical problems (diseases)
 and allowing the user to select their medical history related to those problems. 
 It provides checkboxes to select pre-existing medical problems, 
 and an "Other" option with an input field to add custom medical problems.

**Redux State:
alldiseases: This state variable is obtained using a Redux selector and contains a list of all available medical problems (diseases).
selectedmedicalproblems: This state variable is passed as a prop and contains an array of medical problems that the user has selected.

**State Variables:
input: A state variable to store the value of the input field when the user enters a custom medical problem.

**Functions:
handleMedicalproblems: A function that handles the selection of medical problems (diseases). 
It adds or removes the selected medical problem to/from the selectedmedicalproblems state and dispatches
 actions to add or remove the medical problem from the Redux state.

**Component Structure:

1. The component renders a header with the heading prop.
2. The component displays a list of medical problems (diseases) as checkboxes, fetched from the disease prop. 
  It allows users to select pre-existing medical problems.
3.If the "Other" option is included in the selectedmedicalproblems state, 
it renders an input field to allow users to add custom medical problems.
4.The component uses CSS classes and inline styles to style various elements, 
such as headings, checkboxes, and input fields.

**Usage:
The Medicalproblems component is used to allow users to select their medical history 
related to various medical problems (diseases). It provides a list of pre-existing medical 
problems as checkboxes and allows users to add custom medical problems using an input field. 
The component can be used as part of a user's medical history form or profile page.
 */
