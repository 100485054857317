import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "tw-elements";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./contexts/ContextProvider";
import { Provider } from "react-redux";
import store, { persistedStore } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <ContextProvider>
        <BrowserRouter>
          <StrictMode>
            <App />
            <ToastContainer className="text-xs font-semibold font-membership" />
          </StrictMode>
        </BrowserRouter>
      </ContextProvider>
    </PersistGate>
  </Provider>
);

/**
 * *Documentation: Index File

 The index.js file is the entry point of your React application. 
 It sets up the necessary configurations and renders the root component, 
 which is the starting point for your application.

 **Dependencies:
React: The main library for building the application's user interface.
 react-dom/client: A module from React DOM used to create a root for rendering.
 "index.css": An external stylesheet that provides styles for the entire application.
 "tw-elements": A custom CSS library that likely includes predefined styles and utility classes for rapid development.
 "App" Component: The main component of the application that contains the entire UI and functionality.
 BrowserRouter: A component from React Router DOM that provides routing capabilities to the application.
 ContextProvider: A custom component that wraps the application with a Context to manage global state.
 Provider: A component from React Redux that enables the application to access the Redux store.
 store and persistedStore: The Redux store and persisted store (for Redux-Persist) that hold the application's state.
 PersistGate: A component from Redux-Persist that delays rendering the UI until the persisted state is retrieved.
 ToastContainer: A component from "react-toastify" that displays toast messages.

 **StrictMode:
 The StrictMode is a built-in component from React that performs additional checks and warnings during development. 
 It helps identify potential issues and deprecated practices.

 **Root Rendering:
 The root element of the application is selected using document.getElementById("root"). 
 The createRoot function from React DOM's client module is used to create a root for rendering.
  This allows for concurrent rendering, a feature that improves the application's performance and responsiveness.

 **Rendered Components:
 The following components are rendered within the root:

 Provider: Wraps the entire application with the Redux store to enable access to the global state and actions.
 PersistGate: Delays rendering the UI until the persisted state is retrieved from the persistedStore.
 ContextProvider: Wraps the application with a custom context (from ContextProvider) to manage and share global state across components.
 BrowserRouter: Wraps the entire application with routing capabilities using React Router DOM.
 StrictMode: Wraps the entire application to enable additional checks and warnings during development.
 App: The main component that contains the entire UI and functionality of the application.
 ToastContainer: A component from "react-toastify" library that provides toast messages for displaying notifications

**/
