import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metaccident: "",
  havechildhoodillness: "",
  allfemalecondition: [],
};

const medicalhistoryReducer = createSlice({
  name: "medicalhistory",
  initialState,
  reducers: {
    SetAccident: (state, action) => {
      state.metaccident = action.payload;
    },
    SetChildhoodillness: (state, action) => {
      state.havechildhoodillness = action.payload;
    },
    addFemalecondition: (state, action) => {
      if (
        state.allfemalecondition !== null &&
        state.allfemalecondition !== undefined
      ) {
        state.allfemalecondition = [
          ...state.allfemalecondition,
          action.payload,
        ];
      } else {
        state.allfemalecondition = [action.payload];
      }
    },
    removeFemalecondition: (state, action) => {
      state.allfemalecondition = state.allfemalecondition.filter(
        (allergy) => allergy !== action.payload
      );
    },
  },
});

export const {
  SetAccident,
  SetChildhoodillness,
  addFemalecondition,
  removeFemalecondition,
} = medicalhistoryReducer.actions;

export const metaccident = (state) => state.medicalhistory.metaccident;
export const havechildhoodillness = (state) =>
  state.medicalhistory.havechildhoodillness;
export const allfemalecondition = (state) =>
  state.medicalhistory.allfemalecondition;

export default medicalhistoryReducer.reducer;

/**
 
**Documentation: Medicalhistory Reducer

The medicalhistoryReducer is a slice of the Redux store that manages data related to medical history and conditions. 
It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Initial State:
The initialState defines the initial values for the medicalhistoryReducer slice. It includes three properties:

* metaccident: Holds information about whether the user has met with an accident (initially set to an empty string).
* havechildhoodillness: Holds information about whether the user had any childhood illness (initially set to an empty string).
* allfemalecondition: An array that holds various female health conditions. Initially set to an empty array.

**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following actions are defined:

* SetAccident: An action that sets the metaccident property in the state to the payload value.
* SetChildhoodillness: An action that sets the havechildhoodillness property in the state to the payload value.
* addFemalecondition: An action that adds a female health condition to the allfemalecondition array.
   If the array is empty or not yet defined, it initializes the array with the payload value.
* removeFemalecondition: An action that removes a female health condition from the allfemalecondition array, 
  based on the payload value.

**Action Creators Export:
The action creators (SetAccident, SetChildhoodillness, addFemalecondition, removeFemalecondition) are extracted from 
the medicalhistoryReducer.actions object and exported for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selectors are defined:

* metaccident: Retrieves the metaccident value from the medicalhistoryReducer slice of the state.
* havechildhoodillness: Retrieves the havechildhoodillness value from the medicalhistoryReducer slice of the state.
* allfemalecondition: Retrieves the allfemalecondition array from the medicalhistoryReducer slice of the state.

**Reducer Export:
The generated reducer function (medicalhistoryReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to medical history and condition data in the Redux store.
  
 */
