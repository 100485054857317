import React from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftSquare } from "react-icons/bs";

const Header = ({ title, Button }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="md:m-2 p-2 flex gap-4 items-center">
      {Button ? (
        <button
          type="button"
          onClick={handleClick}
          className=" text-gray-700 capitalize text-2xl font-medium"
        >
          <BsArrowLeftSquare />
        </button>
      ) : null}
      <span className=" text-gray-700  text-xl md:text-2xl font-semibold">
        {title}
      </span>
    </div>
  );
};

export default Header;

/**
 * The Header component is a reusable component used to display a header with a title and an optional back button.

**Component Behavior:

1. The Header component receives two props: title and Button.
2. title: The text to be displayed as the header title.
3. Button: A boolean prop that determines whether the back button should be displayed or not.
4. If the Button prop is true, a back button with the icon "BsArrowLeftSquare" is displayed on the left side of the header. 
  Clicking the back button will navigate the user back to the previous page using the useNavigate hook from react-router-dom.
5. The header title is displayed on the right side of the back button.
 */
