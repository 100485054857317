import React, { useState } from "react";

const BmiCalculator = () => {
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [feet, setFeet] = useState("");
  const [inch, setInch] = useState("");
  const [weightinkg, setWeightinkg] = useState("");
  const [weightinpounds, setWeightinpounds] = useState("");
  const [bmi, setBmi] = useState("00.00");
  const [status, setStatus] = useState(null);
  const [unit, setUnit] = useState("us");

  const units = [
    { id: "1", name: "US Units", value: "us" },
    { id: "2", name: "Metric Units", value: "metric" },
  ];

  const calculateBMI = (e) => {
    e.preventDefault();

    if (unit === "us") {
      // Convert height to total inches
      const heightInInches = parseInt(feet) * 12 + parseInt(inch);

      // Calculate BMI
      const weightInKg = parseFloat(weightinpounds) * 0.453592; // Convert pounds to kilograms
      const heightInMeters = heightInInches * 0.0254; // Convert inches to meters
      const bmiValue = weightInKg / (heightInMeters * heightInMeters);

      setBmi(bmiValue.toFixed(2)); // Round BMI value to two decimal places
    }

    if (unit === "metric") {
      const weightInKg = parseFloat(weightinkg);
      const heightInMeters = parseFloat(height) / 100; // Convert centimeters to meters
      const bmiValue = weightInKg / (heightInMeters * heightInMeters);

      setBmi(bmiValue.toFixed(2)); // Round BMI value to two decimal places
    }

    checkStatus();
  };
  const checkStatus = () => {
    if (bmi < 18.5) {
      setStatus("Underweight");
    } else if (bmi >= 18.5 && bmi < 25) {
      setStatus("Healthy");
    } else if (bmi >= 25 && bmi < 30) {
      setStatus("Overweight");
    } else if (bmi >= 30) {
      setStatus("Obese");
    }
  };

  const clear = () => {
    setHeight("");
    setWeightinkg("");
    setStatus(null);
    setBmi("00.00");
    setAge("");
    setGender("");
    setInch("");
    setFeet("");
    setWeightinpounds("");
  };

  const handleSelect = (value) => {
    if (value === "us") {
      setUnit(value);
    }
    if (value === "metric") {
      setUnit(value);
    }

    return null;
  };
  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="bg-secondary-bg p-3 md:p-6 mt-10 rounded-sm text-gray-700 md:mx-5 mb-5 grid md:grid-cols-2 gap-4">
        <div className="bg-white px-6 py-3 flex flex-col gap-4 rounded-sm">
          <p className="text-xl font-bold text-center">
            FitCheck: Your BMI Buddy
          </p>
          <img src="./assets/bmi.webp" alt="bmi" />
          <p className="font-medium text-center">
            Stay Fit, Know Your BMI: BMI Smart Keeps You on Track!
          </p>
        </div>
        <div className="bg-white rounded-sm px-4 py-2">
          <h2 className="font-semibold text-center text-lg">BMI Calculator</h2>

          <div className="flex border-1 mt-4">
            {units.map((item) => (
              <span
                key={item.id}
                onClick={() => handleSelect(item.value)}
                className={`flex-1 text-center cursor-pointer font-medium  p-2 ${
                  unit === item.value ? "bg-primary-bg text-white" : ""
                }`}
              >
                {item.name}
              </span>
            ))}
          </div>
          <div className="flex flex-col gap-6 mt-4">
            <div>
              <label className="block text-sm font-medium text-primary-color">
                Age
              </label>
              <input
                type="text"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="minimum age 5"
                className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-primary-color">
                Gender
              </label>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            {unit === "us" && (
              <div className="flex flex-col gap-2">
                <label className="block text-sm font-medium text-primary-color">
                  Height
                </label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={feet}
                    onChange={(e) => setFeet(e.target.value)}
                    placeholder="feet"
                    className="mt-1 block flex-1  rounded-sm  border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                  />
                  <input
                    type="text"
                    value={inch}
                    onChange={(e) => setInch(e.target.value)}
                    placeholder="inchs"
                    className="mt-1 block flex-1  rounded-sm  border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                  />
                </div>
              </div>
            )}

            {unit === "metric" && (
              <div>
                <label className="block text-sm font-medium text-primary-color">
                  Height
                </label>
                <input
                  type="number"
                  value={height}
                  placeholder="cm"
                  onChange={(e) => setHeight(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
                />
              </div>
            )}

            {unit === "us" && (
              <div>
                <label className="block text-sm font-medium text-primary-color">
                  Weight
                </label>
                <input
                  type="number"
                  value={weightinpounds}
                  placeholder="lbs"
                  onChange={(e) => setWeightinpounds(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
                />
              </div>
            )}

            {unit === "metric" && (
              <div>
                <label className="block text-sm font-medium text-primary-color">
                  Weight
                </label>
                <input
                  type="number"
                  value={weightinkg}
                  placeholder="kg"
                  onChange={(e) => setWeightinkg(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
                />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <button
                onClick={calculateBMI}
                className="bg-button-bg text-white px-4 py-2 rounded-sm font-medium outline-none flex-1"
              >
                Calculate
              </button>
              <button
                onClick={clear}
                className="border-primary-color border-1 px-4 py-2 rounded-sm font-medium outline-none flex-1"
              >
                Clear
              </button>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 mt-4 text-lg">
              <p className=" text-center font-medium"> Your BMI is</p>
              <span className="bg-secondary-bg p-2 rounded-sm text-xl font-bold">
                {bmi}
              </span>
              {status && (
                <span className="font-bold flex gap-2 text-center">
                  You are -<p className="text-primary-color">{status}</p>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BmiCalculator;

/**
 * 
 
**Documentation: BmiCalculator Component

The BmiCalculator component is a BMI (Body Mass Index) calculator that allows users to calculate
their BMI based on their age, gender, height, and weight. 
The calculator supports both US and Metric units for height and weight measurements. 
The component provides a form to input the required information,
 a "Calculate" button to calculate the BMI, a "Clear" button to reset the input fields, 
and a section that displays the calculated BMI and the corresponding BMI status (e.g., underweight, healthy, overweight, obese).

**State Variables:
1. age: A state variable to store the age input value.
2. gender: A state variable to store the selected gender (Male/Female).
3. height, feet, inch: State variables to store the height input values. (For US units)
4. weightinkg, weightinpounds: State variables to store the weight input values. (For Metric and US units)
5. bmi: A state variable to store the calculated BMI value as a string with two decimal places.
6. status: A state variable to store the BMI status (e.g., "Underweight", "Healthy", "Overweight", "Obese").
7. unit: A state variable to keep track of the selected unit (us/metric) for the calculator.

**Functions:
1. calculateBMI: A function that calculates the BMI based on the user input. It checks the selected unit and performs the BMI calculation accordingly.
2.checkStatus: A function that determines the BMI status based on the calculated BMI value.
3. clear: A function that clears all the input fields and resets the BMI and status values.
4. handleSelect: A function that handles the unit selection (us/metric) from the dropdown.

**Component Structure:
1. The component renders a form with input fields for age, gender, height, and weight, along with a dropdown to select the unit (us/metric) for height and weight measurements.
2. The "Calculate" button triggers the calculateBMI function, which calculates the BMI based on the user's inputs and sets the BMI value using the setBmi state function.
3. The BMI status is determined using the checkStatus function and displayed below the calculated BMI value.
4.The "Clear" button resets all the input fields, BMI, and status values when clicked.

 */
