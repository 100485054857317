import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  user: [],
  userId: null,
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    SetEmail: (state, action) => {
      state.email = action.payload;
    },
    SetUser: (state, action) => {
      state.user = action.payload;
    },
    SetUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { SetUser, SetEmail, SetUserId, SetUserDocExist } =
  userReducer.actions;

export const user = (state) => state.user.user;
export const email = (state) => state.user.email;
export const userId = (state) => state.user.userId;

export default userReducer.reducer;

/**
 **Documentation: User Reducer
The userReducer is a slice of the Redux store that manages user-related data and authentication state.
 It utilizes the createSlice function from @reduxjs/toolkit to generate a reducer with automatically generated action creators.

**Dependencies:
*?@reduxjs/toolkit:
  A package that provides utilities and conventions for working with Redux.
*?Initial State:
The initialState defines the initial values for the userReducer slice. It includes three properties:

* email: Holds the user's email address (initially set to null).
* user: Holds an array of user data (initially set to an empty array).
* userId: Holds the user's unique identifier (initially set to null).


**Reducer Actions:
The createSlice function generates action creators and corresponding reducer functions based on the provided reducers object. 
The following actions are defined:

* SetEmail: An action that sets the email property in the state to the payload value.
* SetUser: An action that sets the user property in the state to the payload value.
* SetUserId: An action that sets the userId property in the state to the payload value.

**Action Creators Export:
The action creators (SetUser, SetEmail, SetUserId, SetUserDocExist) are extracted from the userReducer.actions 
object and exported for use in other parts of the application.

**Selectors:
Selectors are functions that retrieve specific pieces of data from the Redux store. The following selectors are defined:

* user: Retrieves the user array from the userReducer slice of the state.
* email: Retrieves the email value from the userReducer slice of the state.
* userId: Retrieves the userId value from the userReducer slice of the state.

**Reducer Export:
The generated reducer function (userReducer.reducer) is exported as the default export of the module. 
This reducer will handle actions related to user data in the Redux store.
 */
