import React from "react";

const Empty = ({ title }) => {
  return (
    <div className="flex justify-center items-center">
      <span className="font-semibold text-gray-700 text-md">
        no {title} information yet
      </span>
    </div>
  );
};

export default Empty;

/**
 * The Empty component is a reusable component used to display a message indicating that there is no information available for a specific title.

Component Behavior:
1. The Empty component receives a prop called title, which is used to customize the message 
according to the type of information that is missing.
 */
