import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { auth } from "../../firebase";
import { updatePassword } from "firebase/auth";
import Cookies from "js-cookie";

const Changepassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [confirmpassworderror, setConfirmpasswordError] = useState("");
  const [success, setSuccess] = useState("");

  // Function to validate password
  const validatePassword = () => {
    let error = "";

    if (!password.trim()) {
      error = "Password is required";
    } else if (password.trim().length < 8) {
      error = "Password must be atleast 8 characters";
    }
    // else if (
    //   !/[a-z]/.test(password) ||
    //   !/[A-Z]/.test(password) ||
    //   !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)
    // ) {
    //   error =
    //     "Password must contain at least one lowercase letter, one uppercase letter, and one special character";
    // }

    setPasswordError(error);

    return error === "";
  };

  // Function to validate the password and confirmpassword
  const validateConfirmpassword = () => {
    let error = "";
    if (!confirmpassword.trim()) {
      error = "Confirm password is required";
    } else if (password.trim() !== confirmpassword.trim()) {
      error = "Passwords do not match";
    }
    setConfirmpasswordError(error);

    return error === "";
  };

  // Function to update the new password in the firebase
  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordValid = validatePassword();
    const confirmpasswordValid = validateConfirmpassword();

    if (passwordValid && confirmpasswordValid) {
      const user = auth.currentUser;
      const newPassword = password;
      await updatePassword(user, newPassword)
        .then(() => {
          setSuccess("Password successfully updated.");
          setTimeout(() => {
            Cookies.remove("loggedIn");
            navigate("/auth");
          }, 2000);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  return (
    <div className="mt-20 md:mt-10 ml-3 mr-3">
      <Header title="Change Password" />

      {/* form section */}
      <div className="flex justify-center rounded-sm bg-secondary-bg p-3 md:p-10 mt-10  md:ml-3 md:mr-3 md:mb-5 ">
        <div className="w-full md:flex md:justify-center ">
          <form onSubmit={handleSubmit}>
            <div className="bg-white rounded-sm px-4 py-5 md:w-[600px]">
              <div className="flex flex-col w-full gap-6">
                <div>
                  <label
                    htmlFor="new-password"
                    className="block text-sm font-medium text-primary-color"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validatePassword}
                    // className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm sm:text-sm focus:border-primary-color focus:ring-primary-color"
                    className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                    ${
                      passworderror
                        ? "border-red-500 focus:ring-0 focus:border-red-500"
                        : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                    }
                  `}
                  />
                  {passworderror && (
                    <p className="text-xs font-bold text-red-600 mt-1">
                      {passworderror}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="confirm-password"
                    className="block text-sm font-medium text-primary-color"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="text"
                    name="confirm-password"
                    id="confirm-password"
                    autoComplete="confirm-password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    onBlur={validateConfirmpassword}
                    // className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                    ${
                      confirmpassworderror
                        ? "border-red-500 focus:ring-0 focus:border-red-500"
                        : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                    }
                  `}
                  />
                  {confirmpassworderror && (
                    <p className="text-xs font-bold text-red-600 mt-1">
                      {confirmpassworderror}
                    </p>
                  )}
                </div>
              </div>
              {success && (
                <p className="text-xs font-bold text-green-600 mt-2">
                  {success}
                </p>
              )}
            </div>

            <div className="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-sm  border border-transparent bg-primary-color py-2 px-4 text-sm font-medium text-white shadow-sm "
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;

/**
 
**Documentation: Changepassword Component

The Changepassword component represents a page where the user can change their account password. 
It includes a form with input fields for the new password and confirm password, and it provides validation for both fields.
 When the form is submitted with valid input, the user's password is updated,
  and they are redirected to the authentication page after a successful update.

**Dependencies:
react: The core library for building user interfaces in React applications.
react-router-dom: A library that provides routing functionality for React applications.
firebase: The Firebase SDK, which enables authentication and password update functionality.
js-cookie: A library for managing cookies in JavaScript.


**State Variables:
password: Holds the value of the new password input field.
confirmpassword: Holds the value of the confirm password input field.
passworderror: Holds the validation error message for the new password field.
confirmpassworderror: Holds the validation error message for the confirm password field.
success: Holds the success message displayed after a successful password update.


**Hooks:
useNavigate: A hook from react-router-dom that returns a function to navigate to different routes programmatically.
useState: A hook from React that allows using state variables and their updating functions.


**Component Structure:
The component is a functional component that uses the useNavigate hook for navigation and the useState hook to manage state variables.
The component includes a form with input fields for the new password and confirm password, 
along with corresponding error messages and a success message.
Input fields are controlled components, where their values are linked to the state variables (password and confirmpassword)
 and updated using the onChange event handlers.
Validation functions (validatePassword and validateConfirmpassword) are used to validate the input fields 
and set the corresponding error messages.
When the form is submitted, the handleSubmit function is called. It first validates both password fields, and if they are valid, it updates the user's password using Firebase's updatePassword method.
Upon successful password update, the user's loggedIn cookie is removed, and the user is redirected to the authentication page after a 2-second delay.

**Rendered Output:
The component renders a form with input fields for the new password and confirm password, 
along with error messages for validation feedback. Additionally,
 a success message is displayed upon a successful password update.
 */
